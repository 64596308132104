import React from "react";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Client from "client";

// import FormList from "components/form-list/form-list";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import FormList from "components/form-list/form-list";
import Image from "react-bootstrap/Image";

const validationSchema = Yup.object({
  name: Yup.string().label("Name").required(),
  image: Yup.mixed().label("Pattern Image").required(),
  steps: Yup.array().test("all-steps-filled", (vals) => {
    return !vals.some((v) => !v);
  }),
});

export default function CreatePatternModal({
  setShowModal = () => {},
  showModal = true,
  activePattern,
  onSuccess = () => {},
}) {
  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "create-pattern",
    networkMode: "always",
    mutationFn: async (data) =>
      activePattern?.id
        ? Client.put(`/patterns/${activePattern.id}`, data)
        : Client.post("/patterns", data),
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values.body);
    },
  });

  const initialValues = {
    name: activePattern?.name || "",
    image: activePattern?.image || null,
    steps:
      activePattern?.pattern_step?.map((step) => {
        return step.text;
      }) || [],
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnCreate = (formik) => (
    <Button
      type="submit"
      label="Save"
      disabled={!(formik.isValid && formik.dirty)}
      onClick={formik.handleSubmit}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitRequest}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle={activePattern ? "View Pattern" : "Add a Pattern"}
            footerButtons={footerButtons(formik)}
            onHide={() => setShowModal(false)}
          >
            {/* <FormTextInput
              name="name"
              label="Pattern Name"
              formik={formik}
              labelPosition="top"
            /> */}
            <FormElement
              element="InputText"
              id="name"
              name="name"
              label="Pattern name"
              placeholder="Name your pattern"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              required
            />

            {/* <FormFileUpload
              name="image"
              label="Pattern Image"
              formik={formik}
              placeholder="Click to add pattern image"
              helperText="Accepts png and jpeg"
            /> */}
            {activePattern ? (
              <Image
                id="image"
                name="image"
                label="Pattern Image"
                placeholder="Image"
                element="Image"
                formik={formik}
                thumbnail
                width={100}
                src={formik.values.image?.link}
              />
            ) : (
              <FormElement
                element="FileUpload"
                id="image"
                name="image"
                label="Pattern image"
                formik={formik}
                required
              />
            )}
            <FormList
              name="steps"
              label="Directions"
              formik={formik}
              labelPosition="top"
              listItemLabel="number"
              required
              disabled={!!activePattern}
            />

            {/* <FormElement
              element="InputButtonGroup"
              id="steps"
              name="steps"
              label="Directions"
              placeholder="Step"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.steps}
              btn={
                <Button
                  label="Add"
                  icon={<Icon icon="Plus" />}
                  iconPos="left"
                  color="secondary"
                  variant="outlined"
                  // onClick={() => formik.setFieldValue("steps", [...formik.values.steps, ""])}
                  onClick={() => alert("add step functionality")}
                />
              }
            /> */}
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
}
