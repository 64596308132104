import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
  transition,
} from "emotion/utils";

export const css = (theme) => ({
  ...fullWidth(),
  border: `1px solid ${theme?.border?.secondary}`,
  cursor: "pointer",

  "> *": {
    textAlign: "center",
  },

  noImg: {
    ...borderRadius(8),
    ...paddingX(24),
    ...paddingY(16),
    border: `1px solid ${theme?.border?.secondary}`,
    cursor: "pointer",
    // flex: 1,

    dropzone: (isDragOver) => ({
      ...flexbox("column", "center", "center", 16),

      "> *": {
        textAlign: "center",
        width: "auto",
      },
    }),

    "> div:first-of-type": {
      "> div:first-of-type": {
        ...borderRadius(8),
        ...paddingXY(10),
        border: `1px solid ${theme?.border?.secondary}`,
      },
    },

    direct: {
      p: {
        ...fontbox(appFonts.inter, 14, 400, 20),
        color: theme?.text?.tertiary,

        "> span": {
          fontWeight: 600,
          color: theme?.text?.highlight,
        },

        "&:last-of-type": {
          ...fontbox(appFonts.inter, 12, 400, 18),
        },
      },
    },
  },

  withImg: {
    ...borderRadius(8),
    ...paddingXY(16),
    border: `1px solid ${theme?.border?.secondary}`,

    dropzone: (isDragOver) => ({
      ...flexbox("row", "center", "flex-start", 12),
    }),

    icon: (type) => ({
      ...posRelative(),
      paddingLeft: 7,

      "&::before": {
        ...borderRadius(2),
        ...fontbox(appFonts.inter, 10, 700, 12),
        ...paddingX(3),
        ...paddingY(2),
        ...posAbsolute(18, null, null, 0, 1),
        backgroundColor: type.fileTypeColor || theme?.text?.tertiary,
        color: appColors?.grays?.[0],
        content: `"${type.fileType}"`,
      },

      label: (type) => ({
        ...posAbsolute(18, null, null, 0, 1),

        "&::before": {
          ...borderRadius(2),
          ...fontbox(appFonts.inter, 10, 700, 20),
          ...paddingX(3),
          ...posAbsolute(0, null, null, 0, 1),
          backgroundColor: type.fileTypeColor || theme?.text?.tertiary,
          color: appColors?.grays?.[0],
          content: `"${type.fileType}"`,
        },
      }),
    }),

    info: {
      ...flexbox("column", "flex-start", "flex-start", 0),
      flex: 1,

      "> p": {
        ...fontbox(appFonts.inter, 14, 400, 20),
        color: theme?.text?.tertiary,

        "&:first-of-type": {
          color: theme?.text?.secondary,
          fontWeight: 500,
        },

        "> span": {
          fontWeight: 600,
          color: theme?.text?.highlight,
        },

        "&:last-of-type": {
          ...fontbox(appFonts.inter, 12, 400, 18),
        },
      },
    },

    remove: {
      alignSelf: "center",
      cursor: "pointer",

      "> div": {
        ...borderRadius(20),
        ...paddingXY(8),
        ...transition("all", 0.3),
        backgroundColor: "transparent",
        border: `1px solid transparent`,
      },

      "&:hover > div": {
        backgroundColor: appColors.grays[100],
        borderColor: `${appColors.grays[1]}08`,
      },
    },
  },

  "> div:first-of-type": {
    ...borderRadius(8),
    ...paddingXY(10),
    border: `1px solid ${theme?.border?.secondary}`,
  },

  "> div:last-of-type": {
    "> p": {
      ...fontbox(appFonts.inter, 14, 400, 20),
      color: theme?.text?.tertiary,

      "> span": {
        fontWeight: 600,
        color: theme?.text?.highlight,
      },

      "&:last-of-type": {
        ...fontbox(appFonts.inter, 12, 400, 18),
      },
    },
  },
});
