/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useMemo, useState } from "react";

import Icon from "components/icon/icon";

import { appColors } from "emotion/utils";

import { css } from "./css";
import { useParams } from "react-router";

export default function DragAndDrop({
  file,
  placeholder,
  onClick,
  accept,
  onChange,
  className,
  show,
}) {
  const theme = useTheme();
  console.log("show is...", show);

  // Create a URL for the uploaded file
  const fileUrl = useMemo(() => {
    if (!file) return placeholder;

    if (file instanceof File) {
      return URL.createObjectURL(file);
    }

    return file;
  }, [file, placeholder]);

  const [isDragOver, setIsDragOver] = useState(false);
  const [cursorStyle, setCursorStyle] = useState("default");

  const acceptAry =
    accept
      ?.replace(/\.png/i, "application/png,image/png")
      .replace(/\.jpg/i, "application/jpg,image/jpg")
      .replace(/\.jpeg/i, "application/jpeg,image/jpeg")
      .replace(/\.pdf/i, "application/pdf")
      .split(",") || [];

  const dropHandler = (e) => {
    e.preventDefault();

    if (!e.dataTransfer.items) {
      return;
    }

    Array.from(e.dataTransfer.items).forEach((item, i) => {
      if (
        item.kind !== "file" ||
        !onChange ||
        acceptAry.indexOf(item.type) < 0
      ) {
        return;
      }

      const file = item.getAsFile();
      onChange(file);
    });
  };

  // prevent browser's default drag behavior
  const dragOverHandler = (ev) => {
    setIsDragOver(true);

    //Check file type
    //Change cursor
    let badData;
    document.body.style.cursor = "pointer";
    if (ev.dataTransfer?.items?.length > 0) {
      Array.from(ev.dataTransfer?.items).forEach((item) => {
        if (item.kind === "file" && acceptAry.indexOf(item.type) > -1) {
          badData = badData || false;
        } else {
          badData = true;
        }
      });
    }

    if (badData) {
      //TODO: Set transperancy to indicate this will NOT be accepted
    } else {
      //TODO: Set transperancy to indicate this will be accepted
    }
    ev.preventDefault();
  };

  const logo = (
    <div css={css(theme).noImg}>
      <div
        css={css().noImg.dropzone()}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onClick={onClick}
      >
        <Icon icon="UploadCloud" />
        <div css={css(theme).noImg.direct}>
          <p>
            <span>Click to upload</span> or drag and drop
          </p>
          <p>SVG, PNG, PDF or JPG (max 600x300px)</p>
        </div>
      </div>
    </div>
  );

  if (!file) {
    return logo;
  }

  const getFileTypeFromFilename = (filename) => {
    if (!filename) return null;

    const extension = filename.split(".").pop().toLowerCase();
    const mimeTypes = {
      png: "image/png",
      jpg: "image/jpg",
      jpeg: "image/jpeg",
      pdf: "application/pdf",
      svg: "image/svg+xml",
    };

    return mimeTypes[extension] || null;
  };

  const getFileType = (type) => {
    console.log("type is...", type);

    const fileTypes = {
      "image/png": { fileType: "PNG", fileTypeColor: appColors?.reds?.[600] },
      "image/jpeg": { fileType: "JPG", fileTypeColor: appColors?.brand?.[600] },
      "image/jpg": { fileType: "JPG", fileTypeColor: appColors?.brand?.[600] },
      "application/pdf": {
        fileType: "PDF",
        fileTypeColor: appColors?.blues?.placeholder,
      },
      "image/svg+xml": {
        fileType: "SVG",
        fileTypeColor: appColors?.grays?.[600],
      },
    };

    return (
      fileTypes[type] || {
        fileType: "UNKNOWN",
        fileTypeColor: appColors?.grays?.[600],
      }
    );
  };

  const getIcon = () => {
    const fileType =
      file?.type || getFileTypeFromFilename(show?.avatar?.filename);
    return (
      <div css={css().withImg.icon(getFileType(fileType))}>
        <Icon icon="Page" />
      </div>
    );
  };

  //const fileSizeInKB = file ? (file.size / 1024).toFixed() : 0;

  return (
    <div css={css(theme).withImg}>
      <div
        css={css(theme).withImg.dropzone(isDragOver)}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onChange={onChange}
        onClick={onClick}
      >
        {getIcon()}

        <div css={css(theme).withImg.info}>
          <p>{file?.name || show?.avatar?.filename}</p>
        </div>

        <div
          css={css(theme).withImg.remove}
          // onClick={() => alert('remove functionality')}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(null);
          }}
        >
          <Icon icon="Trash" />
        </div>
      </div>
    </div>
  );
}
