const ENV = process.env.NODE_ENV;
const SERVER_PROTOCOL = process.env.REACT_APP_SERVER_PROTOCOL;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

const isIP = (str) => {
  const regex = new RegExp(
    "^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
  );
  return regex.test(str);
};

const parseResponse = async (response) => {
  let jsonRsp = null;
  try {
    jsonRsp = await response.json();
  } catch (e) {
    console.error("could not parse res body");
  }

  if (!response.ok) {
    const bodyError = jsonRsp?.error?.message || jsonRsp?.error || "Error";
    const err = new Error(bodyError);

    err.field = jsonRsp?.field;
    err.status = response.status;
    throw err;
  }
  return jsonRsp;
};

const createBody = (data = {}) => {
  const isMultipart = !!Object.values(data).find((v) => v instanceof File);

  if (!isMultipart) {
    return JSON.stringify(data);
  }

  const formbody = new FormData();
  const json = {};
  Object.entries(data).forEach(([key, val]) => {
    if (val instanceof File) {
      formbody.append(key, val);
    } else {
      json[key] = val;
    }
  });

  formbody.append("json", JSON.stringify(json));

  return formbody;
};

const createClient = (endpoint) => {
  const callApi = async (method, path, data) => {
    const now = new Date();

    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    const hour = now.getHours();
    const clientDate = new Date(year, month, day, hour, 0, 0).toISOString();

    const token = localStorage.getItem("hhhh_refresh");

    const options = {
      method,
      mode: "cors",
      headers: {
        Accept: "application/json",
        token,
        "x-client-date": clientDate,
      },
    };

    if (["POST", "PATCH", "PUT"].includes(method)) {
      const body = createBody(data);
      options.body = body;
      if (!(body instanceof FormData)) {
        options.headers["Content-Type"] = "application/json";
      }
    }

    try {
      const url = `${endpoint}${path}`;
      const response = await fetch(url, options);
      return await parseResponse(response);
    } catch (error) {
      throw error;
    }
  };

  return {
    get: (path) => callApi("GET", path),
    post: (path, data) => callApi("POST", path, data),
    patch: (path, data) => callApi("PATCH", path, data),
    put: (path, data) => callApi("PUT", path, data),
    delete: (path) => callApi("DELETE", path),
  };
};

const Client = createClient("/api");

export default Client;
