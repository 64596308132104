import React, { useRef, useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Client from "client";

import FormTextInput from "components/form-text-input";
import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import FormCurrencyInput from "components/form-currency-input";
import CustomModal from "components/custom-modal/custom-modal";
import CustomButton from "components/custom-button/custom-button";
import { FormElement } from "components/form-element/form-element";
import { FormErrorMessage } from "validation/form-error-message";
import Button from "components/button/button";

export default function ArenaModal({
  setShowModal = () => {},
  showModal = true,
  arena,
  locationId,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  const formRef = useRef();

  const handleClose = () => [setShowModal(false)];
  const [isFormDirty, setIsFormDirty] = useState(false);
  const conditionalClose = () => {
    // if (isFormDirty) {
    //   handleExit();
    // } else {
    setShowModal(false);
    // }
  };

  const arenaToFormik = () => {
    return {
      name: arena?.name || "",
      cameraUrl: arena?.camera_url || "",
      icon: arena?.icon?.link || null,
      locationId,
      classOffset: arena?.class_offset,
    };
  };
  const initialValues = arenaToFormik();

  const numberMask = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: false,
    decimalSymbol: "",
    decimalLimit: "0", // how many digits allowed after the decimal
    integerLimit: 2, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const headerTitle = `${arena ? `Edit arena` : "Add arena"}`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnCreate = (formik) => (
    <Button
      disabled={!(formik.isValid && formik.dirty)}
      label="Save"
      onClick={(e) => {
        formik.handleSubmit(e);
        // calculateModalHeight();
      }}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={async (data) => {
        data.classOffset = Number(data.classOffset);
        let serverReturn = null;
        if (arena?.id) {
          serverReturn = await Client.put(`/arenas/${arena?.id}`, data);
        } else {
          serverReturn = await Client.post("/arenas", data);
        }
        if (serverReturn?.success === false) {
          const field = serverReturn?.field;
          formRef.current?.setErrors({ [field]: serverReturn?.reason });
        } else {
          setShowModal(false);
          onSuccess(serverReturn);
        }
      }}
      validationSchema={Yup.object({
        name: Yup.string().label("Name").required(),
        cameraUrl: Yup.string().matches(re, "URL is not valid"),
      })}
    >
      {(formik) => (
        <Form
          onChange={(evt) => {
            const clone = structuredClone(formik.values);
            clone[evt.target.name] = evt.target.value;
            setIsFormDirty(
              JSON.stringify(clone) !== JSON.stringify(arenaToFormik()),
            );
          }}
        >
          <CustomModal
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={conditionalClose}
            size="md"
          >
            <FormElement
              element="InputText"
              id="name"
              name="name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              label="Arena name"
              required
            />
            {/* <FormErrorMessage name="name" formik="formik" /> */}

            <FormElement
              element="InputGroup"
              id="cameraUrl"
              name="cameraUrl"
              label="Camera URL (optional)"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.cameraUrl}
            />

            <FormCurrencyInput
              name="classOffset"
              label="Auto Class Number Offset"
              maskOptions={numberMask}
              formik={formik}
              style={{ display: "none" }}
            />

            {/*
              <FormTextInput
                name="capacity"
                label="Capacity"
                formik={formik}
                number
              />
              <FormErrorMessage name="capacity" />

              <FormFileUpload
                name="icon"
                label="Icon"
                formik={formik}
                placeholder="Add Arena Image"
              />
              */}
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
}
