/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import { useMutation, useQuery } from "hooks";

import CustomDivTable from "components/custom-table/custom-div-table";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import ButtonIcon from "components/button-icon/button-icon";
import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import Tag from "components/tag/tag";

import AwardsModal from "./awards-modal";
import MemberModal from "./member-modal";
import CustodianModal from "./custodian-modal";
import HorseModal from "./horse-modal";

import {
  capitalizeFirstLetter,
  getAgeFromAgeCategories,
  truncateText,
} from "utils";

import Client from "client";

export default function Members() {
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showCustodianModal, setShowCustodianModal] = useState(false);
  const [showHorseModal, setShowHorseModal] = useState(false);
  const [showAwardsModal, setShowAwardsModal] = useState(false);
  const [ageCategories, setAgeCategories] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [activeBtnGroupAgeCategories, setActiveBtnGroupAgeCategories] =
    useState("all");
  const [activeBtnGroupStatus, setActiveBtnGroupStatus] = useState("all");

  const theme = useTheme();

  const navigate = useNavigate();

  // const [memberRemoveModal, setMemberRemoveModal] = useState(false);
  const activeMember = useRef(null);
  const toastRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const onSuccess = async (res) => {
    setData(res.data);
    setCount(res.count);
    setAgeCategories(res.age_categories);

    const memberId = searchParams.get("id");
    if (!memberId) return;

    try {
      activeMember.current = { id: memberId };
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    refetch({ offset: 0, limit: 10 });
  }, []);

  useEffect(() => {
    refetch({ offset: 0, limit: 10 });
  }, [activeBtnGroupAgeCategories, activeBtnGroupStatus]);

  const { mutate: refetch, isLoading } = useMutation({
    mutationFn: (params) => {
      let url = `/members?&offset=${params.offset || 0}&limit=${
        params.limit || 10
      }`; //ageFilter=${true}
      if (params.sort && params.order) {
        url += `&sort=${params.sort}&order=${params.order}`;
      }

      if (params.search) {
        url += `&search=${params.search}`;
      }
      if (activeBtnGroupAgeCategories !== "all")
        url += `&age_cat=${activeBtnGroupAgeCategories}`;

      if (activeBtnGroupStatus !== "all")
        url += `&status=${activeBtnGroupStatus === "active" ? true : false}`;

      return Client.get(url);
    },
    onSuccess,
  });

  const rows = useMemo(
    () =>
      data
        .map((row) => ({
          ...row,
          name: `${row.user.first_name} ${row.user.last_name}`,
          email: row.user.email,
        }))
        .filter(
          (r) =>
            r.age_category?.name !== "Aged Out" &&
            r.age_category?.name !== "Too Young",
        ),
    [data],
  );

  const { data: dataAgeCategories, refetch: refetchLevels } = useQuery(
    "/class-age-categories",
  );

  const ageCategoriesOptionsFiltered = dataAgeCategories?.data.map(
    (option) => option.name,
  );

  // function openEditModal(data) {
  //   activeMember.current = data;
  //   searchParams.set("id", data.id);
  //   setSearchParams(searchParams);

  //   setShowMemberModal(true);
  // }

  // function openCustodianModal(data) {
  //   activeMember.current = data;
  //   searchParams.set("id", data.id);
  //   setSearchParams(searchParams);

  //   setShowCustodianModal(true);
  // }

  function onModalClose() {
    searchParams.delete("id");
    setSearchParams(searchParams);
  }

  // function openRemoveModal(data, archive) {
  //   activeMember.current = { data, archive };
  //   setMemberRemoveModal(true);
  // }

  const columns = [
    {
      name: "name",
      valFn: (data) =>
        `${capitalizeFirstLetter(data.user.first_name)} ${capitalizeFirstLetter(
          data.user.last_name,
        )}`,
      display: "Member Name",
      sortable: true,
    },
    {
      name: "age",
      valFn: (data) => data.age_cat || "--",
      display: "Age Group",
    },
    {
      name: "level",
      valFn: (data) =>
        data?.rider_level?.name ? data?.rider_level?.name : "--",
      display: "Rider Level",
    },
    {
      name: "club",
      valFn: (data) => {
        const currentClub = data?.club?.name || "--";

        return truncateText(currentClub);
      },
      display: "Club",
    },
    {
      name: "status",
      default: () => "",
      valFn: (data) => {
        return (
          <Tag tag={data?.status?.banned === "true" ? "Inactive" : "Active"} />
        );
      },
      display: "Status",
    },
    {
      name: "actions",
      valFn: (data) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="ChevronRight" />}
            onClick={() => {
              navigate(`./${data.id}`);
            }}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "center",
      alignHeader: "center",
    },
    // {
    //   name: "actions",
    //   valFn: (it) => (
    //     <CustomDivTableActions>
    //       <ButtonIcon
    //         icon={<Icon icon="UserSquare" />}
    //         onClick={() => {
    //           handleShowCustodian(it);
    //         }}
    //       />
    //       <ButtonIcon
    //         icon={<Icon icon="HorseHead" nostroke />}
    //         onClick={() => {
    //           handleShowHorse(it);
    //         }}
    //       />
    //       <ButtonIcon
    //         icon={<Icon icon="Award" />}
    //         onClick={() => {
    //           handleShowAwards(it);
    //         }}
    //       />
    //       <ButtonIcon
    //         icon={<Icon icon="ChevronRight" />}
    //         onClick={() => {
    //           // openEditModal(it);
    //           navigate(`./${it.id}`);
    //         }}
    //       />
    //     </CustomDivTableActions>
    //   ),
    //   display: "",
    //   noSort: true,
    //   actionCol: true,
    // },
  ];

  if (isLoading && !data) {
    return <span>Loading...</span>;
  }

  const handleAddMember = () => {
    activeMember.current = null;
    setShowMemberModal(true);
  };
  const btnCreate = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add member"
      onClick={handleAddMember}
    />
  );

  const actions = [btnCreate];

  const filterBtnGroupLevels = (
    <FilterBtnGroup
      options={ageCategoriesOptionsFiltered}
      activeBtnGroup={activeBtnGroupAgeCategories}
      setActiveBtnGroup={setActiveBtnGroupAgeCategories}
    />
  );

  const filterBtnGroupStatus = (
    <FilterBtnGroup
      options={["Active", "Inactive"]}
      activeBtnGroup={activeBtnGroupStatus}
      setActiveBtnGroup={setActiveBtnGroupStatus}
    />
  );

  const titleBlock = (
    <TitleBlock>
      {filterBtnGroupLevels}
      {filterBtnGroupStatus}
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </TitleBlock>
  );

  const modals = (
    <>
      {showMemberModal && (
        <MemberModal
          setShowModal={setShowMemberModal}
          activeMember={activeMember.current}
          onSuccess={refetch}
          onClose={onModalClose}
        />
      )}
      {showCustodianModal && (
        <CustodianModal
          setShowModal={setShowCustodianModal}
          activeMember={activeMember.current}
          onSuccess={refetch}
          onClose={onModalClose}
        ></CustodianModal>
      )}
      {showHorseModal && (
        <HorseModal
          setShowModal={setShowHorseModal}
          activeMember={activeMember.current}
          onSuccess={refetch}
          onClose={onModalClose}
        ></HorseModal>
      )}
      {showAwardsModal && (
        <AwardsModal
          setShowModal={setShowAwardsModal}
          activeMember={activeMember.current}
          onSuccess={refetch}
          onClose={onModalClose}
        ></AwardsModal>
      )}
    </>
  );

  return (
    <>
      {titleBlock}

      <CustomDivTable
        onPage={refetch}
        onFilter={console.log}
        count={count}
        rowCount={10}
        columns={columns}
        rows={rows}
        noTableSort="false"
        filterKeys={["name"]}
        showSearch={true}
        selectionMode="single"
        emptyMessage="No Members Found"
        onSelect={(it) => navigate(`./${it?.id}`)}
      />
      {modals}
    </>
  );
}
