/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef, useMemo } from "react";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { Image } from "primereact/image";

import { useQuery } from "hooks";

import LocationModal from "./location-modal";
import LocationRemoveModal from "./location-remove-modal";
import ArenaModal from "./arena-modal";
import ArenaRemoveModal from "./arena-remove-modal";

import CustomDivTable from "components/custom-table/custom-div-table";
import EmptyBlock from "components/empty-block/empty-block";
import { FormElement } from "components/form-element/form-element";
import LocationTitle from "./components/title/title";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import ButtonIcon from "components/button-icon/button-icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";

import dim from "images/dim.svg";

import { css } from "./css";

export default function Locations() {
  const activeArena = useRef(null);

  const queryClient = useQueryClient();

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showLocationRemoveModal, setShowLocationRemoveModal] = useState(false);
  const [showArenaModal, setShowArenaModal] = useState(false);
  const [showArenaRemoveModal, setShowArenaRemoveModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [modalLocation, setModalLocation] = useState(null);

  const theme = useTheme();

  const { data, refetch, isLoading } = useQuery("/locations");
  const { data: arenas, refetch: refetchArenas } = useQuery(
    `/arenas?locationId=${selectedLocation?.id}`,
  );

  useMemo(() => {
    setSelectedLocation(data?.data[0]);
  }, [data]);

  useMemo(async () => {
    await refetchArenas();
  }, [selectedLocation]);

  const arenaData = useMemo(
    () =>
      arenas?.data.map((arena) => ({
        ...arena,
        icon_url: arena.icon?.link,
      })),
    [arenas?.data],
  );

  function editArena(it) {
    activeArena.current = it;
    setShowArenaModal(true);
  }

  // function flipArenaActivation(it) {
  //   activeArena.current = it;
  //   setShowArenaRemoveModal(true);
  // }

  const columns = [
    {
      name: "name",
      valFn: (data) => `${data.name ? data.name : "--"}`,
      display: "Arena",
    },
    {
      name: "cameraUrl",
      valFn: (data) => `${data.camera_url ? data.camera_url : "--"}`,
      display: "Camera URL",
    },
    {
      name: "createdAt",
      valFn: (data) =>
        `${
          data.created_at ? moment(data.created_at).format("MM/DD/yyyy") : "--"
        }`,
      display: "Date Created",
      align: "right",
      alignHeader: "right",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            // onClick={async () => {
            //   const confirmed = window.confirm(
            //     "Are you sure you want to delete this arena?",
            //   );
            //   if (confirmed) {
            //     const serverReturn = await Client.delete(`/arenas/${it?.id}`);
            //     if (!serverReturn.archived_at) {
            //       alert("Something went wrong during deletion");
            //     }
            //   }
            // }}
            onClick={() => {
              activeArena.current = it;
              setShowArenaRemoveModal(true);
            }}
          />
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => editArena(it)}
          />
        </CustomDivTableActions>
      ),
      display: () => btnAddArena,
      noSort: true,
      actionCol: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const setLoc = (locId, data) => {
    const newLoc = (data?.data || []).find((loc) => loc.id === locId);
    if (newLoc) {
      setSelectedLocation(newLoc);
      return;
    }
    setSelectedLocation(null);
  };

  const onChangeSuccess = async (changeLoc) => {
    await queryClient.invalidateQueries({ queryKey: ["locations"] });
    const updatedData = await refetch();
    setLoc(changeLoc?.id, updatedData.data);
  };

  // const onLocationChange = (e) => setLoc(e.target.value, data);

  const handleLocationCreate = () => {
    setIsEditing(false);
    setModalLocation({});
    setShowLocationModal(true);
  };
  const btnAddLocation = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add location"
      onClick={handleLocationCreate}
      variant="outlined"
    />
  );

  const handleLocationEdit = (loc) => {
    setIsEditing(true);
    setModalLocation(loc);
    setShowLocationModal(true);
  };
  // const btnEdit = (
  //   <CustomButton
  //     label="Edit"
  //     color="primary"
  //     type="outlined"
  //     onClick={handleLocationEdit}
  //     align="right"
  //   />
  // );

  const handleArenaRemove = () => {
    setShowLocationRemoveModal(true);
  };
  // const btnRemove = <CustomButtonn label='Remove' color='tertiary' type='outlined' onClick={handleArenaRemove} disabled={!selectedLocation} />;

  const handleArenaCreate = () => {
    activeArena.current = null;
    setShowArenaModal(true);
  };
  const btnAddArena = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add arena"
      onClick={handleArenaCreate}
    />
  );

  const btnEditLocation = (loc) => (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Edit Location"
      onClick={() => handleLocationEdit(loc)}
    />
  );

  // const filterBlock = (
  //   <div className={css.filterBtnsBlock}>
  //     <FilterSelect
  //       data={data}
  //       name="location"
  //       onChange={onLocationChange}
  //       showOptionAll={false}
  //       value={selectedLocation?.id || ""}
  //     />

  //     <div className="buttonBar">
  //       {btnEdit}
  //       {/* {btnRemove} */}
  //       {btnAddLocation}
  //     </div>
  //   </div>
  // );

  // console.log("LOCATIONS");
  // console.log("selectedLocation", selectedLocation);

  const titleBlock = (loc, index) => {
    const actions =
      index === 0
        ? [btnAddLocation, btnEditLocation(loc)]
        : [btnEditLocation(loc)];

    return (
      <TitleBlock>
        <LocationTitle
          loc={loc}
          onClick={handleLocationEdit}
          actions={actions.map((btn, index) => (
            <React.Fragment key={index}>{btn}</React.Fragment>
          ))}
        />
      </TitleBlock>
    );
  };

  const contentBlock = (loc) => (
    <div css={css(theme).content}>
      <Image
        src={(loc?.map || {}).link || dim}
        alt=""
        // preview
      />
      <Image
        src={(loc?.event_map || {}).link || dim}
        alt=""
        // preview
      />

      <FormElement
        element="InputTextarea"
        id="loc_notes"
        name="loc_notes"
        value={loc?.notes || ""}
        label="Notes"
        readOnly={true}
        placeholder=""
      />
    </div>
  );

  const tableBlock = (loc) =>
    selectedLocation?.id && (
      <CustomDivTable
        // title="Arena"
        columns={columns}
        rows={arenaData}
        // selectionMode="single"
        // onSelect={(it) => editArena(it)}
        filterKeys={["name"]}
        showSearch={false}
        emptyMessage="No Locations Found"
      />
    );

  const locationBlock =
    data?.data &&
    data?.data?.map((loc, index) => (
      <div key={loc?.id} css={css(theme).location}>
        {titleBlock(loc, index)}
        {contentBlock(loc)}
        {tableBlock(loc)}
      </div>
    ));

  const emptyBlock = (
    <EmptyBlock
      title="No Locations Yet!"
      subtitle="Start by adding a Location."
      btnLabel="Add a Location"
      onClick={() => {
        setShowLocationModal(true);
      }}
    />
  );

  const content = selectedLocation ? locationBlock : emptyBlock;

  const modals = (
    <>
      {showLocationModal && (
        <LocationModal
          setShowModal={setShowLocationModal}
          //location={selectedLocation}
          location={modalLocation}
          isEditing={isEditing}
          onSuccess={onChangeSuccess}
        />
      )}
      {showLocationRemoveModal && (
        <LocationRemoveModal
          setShowModal={setShowLocationRemoveModal}
          location={selectedLocation}
          onAccept={onChangeSuccess}
        />
      )}
      {showArenaModal && (
        <ArenaModal
          setShowModal={setShowArenaModal}
          arena={activeArena.current}
          onSuccess={refetchArenas}
          locationId={selectedLocation.id}
        />
      )}
      {showArenaRemoveModal && (
        <ArenaRemoveModal
          setShowModal={setShowArenaRemoveModal}
          arena={activeArena.current}
          onAccept={refetchArenas}
        />
      )}
    </>
  );

  return (
    <div css={css(theme)}>
      {content}
      {modals}
    </div>
  );
}
