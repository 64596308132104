import { React } from "react";

export default function FormListText(props) {
  const {
    value,
    name,
    errorClass,
    placeholder,
    onChange,
    onBlur,
    onRemove,
    noActions,
    disabled,
  } = props;

  const removeBtn =
    noActions || disabled ? null : <button onClick={onRemove}>-</button>;

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <input
        type="text"
        className={errorClass}
        id={name}
        name={name}
        onChange={onChange}
        value={value || ""}
        placeholder={placeholder || "Type item..."}
        onBlur={onBlur}
        disabled={disabled}
      />
      {removeBtn}
    </div>
  );
}
