/**@jsxImportSource @emotion/react */
import React, { useRef } from "react";
import { Form, Formik } from "formik";

import Button from "components/button/button";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import { FormErrorMessage } from "validation/form-error-message";

import { appColors, borderRadius, fullWidth, paddingXY } from "emotion/utils";
import { useParams } from "react-router";
import { useQuery } from "hooks";
import Client from "client";

const guardianTypes = [
  { label: "Parent", value: "PARENT" },
  { label: "Grand Parent", value: "GRAND_PARENT" },
  { label: "Guardian", value: "GUARDIAN" },
];

const ModalAddGuardian = ({ onHide, member_guardian, refetch }) => {
  const { memberId } = useParams();

  const { data: memberData } = useQuery(`/members/${memberId}`);

  const headerTitle = `Add guardian to ${memberData?.member?.user?.first_name} ${memberData?.member?.user?.last_name}`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => onHide(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnSubmitCreate = (formik) => (
    <Button
      label={member_guardian ? "Add Guardian" : "Save"}
      disabled={!formik.isValid || !Object.values(formik.touched).length}
      type="submit"
      onClick={(e) => {
        formik.handleSubmit(e);
      }}
      align="right"
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnSubmitCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  const guardianToFormik = () => {
    return {
      first_name: member_guardian?.user?.first_name || "",
      last_name: member_guardian?.user?.last_name || "",
      relation: member_guardian?.relation || "",
      email: member_guardian?.user?.email || "",
      is_emergency_contact: member_guardian?.is_emergency_contact || false,
    };
  };
  const formRef = useRef();
  const initialValues = guardianToFormik();

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={async (data) => {
        let serverReturn = null;
        serverReturn = await Client.put(
          `/members/${memberId}/custodian/`,
          data,
        );
        if (serverReturn?.success === false) {
          const field = serverReturn?.field;
          formRef?.current?.setErrors({
            [field]: serverReturn?.reason,
          });
        } else {
          onHide();
          refetch();
        }
      }}
    >
      {(formik) => (
        <Form>
          <CustomModal
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={onHide}
          >
            <FormElement
              element="InputText"
              id="first_name"
              name="first_name"
              label="First name"
              placeholder="Guardian first name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik?.values?.first_name}
              required
            />
            <FormErrorMessage name="first_name" formik={formik} />

            <FormElement
              element="InputText"
              id="last_name"
              name="last_name"
              label="Last name"
              placeholder="Guardian last name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik?.values?.last_name}
              required
            />
            <FormErrorMessage name="last_name" formik={formik} />

            <FormElement
              element="Dropdown"
              id="relation"
              name="relation"
              label="Relationship"
              placeholder="Select guardian relationship"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik?.values?.relation}
              options={guardianTypes}
              required
            />
            <FormErrorMessage name="last_name" formik={formik} />

            <FormElement
              element="InputText"
              id="email"
              name="email"
              label="Email"
              placeholder="Guardian's email"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik?.values?.email}
              required
            />
            <FormErrorMessage name="email" formik={formik} />

            <div
              style={{
                ...borderRadius(12),
                ...fullWidth(),
                ...paddingXY(16),
                border: `1px solid ${appColors.blues.blueGray}`,
              }}
            >
              <FormElement
                element="Checkbox"
                id="is_emergency_contact"
                name="is_emergency_contact"
                label="This person is an emergency contact"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik?.values?.is_emergency_contact}
              />
            </div>
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
};

export default ModalAddGuardian;
