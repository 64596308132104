/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useMemo } from "react";

import { appColors } from "emotion/utils";

import Icon from "components/icon/icon";

import { css } from "./css";

export default function DragAndDropPdf({
  file,
  placeholder,
  onClick,
  onChange,
  className,
  fileType = "application/pdf",
  show,
}) {
  const theme = useTheme();

  // Create a URL for the uploaded file
  const fileUrl = useMemo(() => {
    if (!file) return placeholder;

    if (file instanceof File) {
      return URL.createObjectURL(file);
    }

    return file;
  }, [file, placeholder]);

  const dropHandler = (e) => {
    e.preventDefault();

    if (!e.dataTransfer.items) {
      return;
    }

    Array.from(e.dataTransfer.items).forEach((item, i) => {
      if (item.kind !== "file" || !onChange) {
        return;
      }

      const file = item.getAsFile();
      if (file.type !== fileType) {
        return;
      }
      onChange(file);
    });
  };

  // prevent browser's default drag behavior
  const dragOverHandler = (ev) => {
    ev.preventDefault();
  };

  // const link = formik.values.avatar?.link;
  // const link =  formik.values.avatar?.link ||
  //               formik.values.icon?.link ||
  //               formik.values.map?.link ||
  //               formik.values.eventMap?.link;

  const url = typeof file === "string" ? file : null;

  const logo = (
    <div css={css(theme).noImg}>
      <div
        css={css().noImg.dropzone}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onClick={onClick}
      >
        <Icon icon="UploadCloud" />
        <div css={css(theme).noImg.direct}>
          <p>
            <span>Click to upload</span> or drag and drop
          </p>
          <p>PDF (max filesize 20mb)</p>
        </div>
      </div>
    </div>
  );

  if (!file) {
    return logo;
  }

  const uniqueStyle = {};
  if (url) {
    uniqueStyle.backgroundImage = `url(${url})`;
  }

  const getFileTypeFromFilename = (filename) => {
    if (!filename) return null;

    const ext = filename.split(".").pop().toLowerCase();
    const fileTypes = {
      png: "image/png",
      jpg: "image/jpg",
      jpeg: "image/jpeg",
      pdf: "application/pdf",
      svg: "image/svg+xml",
    };

    return fileTypes[ext] || null;
  };

  const getFileType = (type) => {
    const fileTypes = {
      "image/png": {
        fileType: "PNG",
        fileTypeColor: appColors?.blues?.bgBrand,
      },
      "image/jpeg": { fileType: "JPG", fileTypeColor: appColors?.brand?.[600] },
      "image/jpg": { fileType: "JPG", fileTypeColor: appColors?.brand?.[600] },
      "application/pdf": {
        fileType: "PDF",
        fileTypeColor: appColors?.reds?.[600],
      },
    };

    return (
      fileTypes[type] || {
        fileType: "SVG",
        fileTypeColor: appColors?.grays?.[600],
      }
    );
  };

  const getIcon = () => {
    const fileType =
      file?.type || getFileTypeFromFilename(show?.waiver?.filename);
    return (
      <div css={css().withImg.icon(getFileType(fileType))}>
        <Icon icon="Page" />
      </div>
    );
  };

  //const fileSizeInKB = file ? (file.size / 1024).toFixed() : 0;

  return (
    <div css={css(theme).withImg}>
      {/* <embed
        src={fileUrl}
        width="100%"
        height="100%"
        type="application/pdf"
      ></embed> */}

      <div
        css={css(theme).dropzone}
        onDrop={dropHandler}
        // onDragOver={dragOverHandler}
        onChange={onChange}
        onClick={onClick}
      >
        {getIcon()}

        <div css={css(theme).withImg.info}>
          <p>{file?.name || show?.waiver?.filename}</p>
        </div>

        <div
          css={css(theme).withImg.remove}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(null);
          }}
        >
          <Icon icon="Trash" />
        </div>
      </div>
    </div>
  );
}
