import moment from "moment";

export function capitalizeFirstLetter(word) {
  if (!word) {
    return "";
  }

  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const formatInputToUTCString = (datetimeLocalString) => {
  const date = new Date(datetimeLocalString);
  return date.toISOString();
};

export function dateAsServerString(dateLocalString) {
  const myMomentObject = moment(dateLocalString, "YYYY-MM-DD");
  return myMomentObject.toISOString();
}

export function truncateText(text, maxLength = 40) {
  if ((text || []).length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}

export function getAgeFromAgeCategories(ageCategories, age) {
  if (!ageCategories || !Array.isArray(ageCategories)) return "";
  for (let category of ageCategories) {
    if (age >= category.start_age && age <= category.end_age) {
      return category.name;
    }
  }

  return "";
}

export function getBgColor(index) {
  const bgColor = index % 2 === 0 ? "#f6f6f6" : "#ffffff";

  return bgColor;
}

export function formattedPhone(phone) {
  // Remove all non-integer characters
  const cleaned = phone.replace(/\D/g, "");

  // Format the phone number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
}
