/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useRef } from "react";

import DragAndDropPdf from "components/drag-ndrop-pdf/drag-ndrop-pdf";
import DragAndDrop from "components/drag-ndrop/drag-ndrop";

import { cssFormElements } from "../css";

export default function FormFileUpload(props) {
  const {
    required,
    formik,
    size,
    name,
    label,
    placeholder,
    displaytype = "image",
    accept = displaytype === "image" ? ".svg,.png,.pdf,.jpeg,.jpg" : ".pdf",
    show,
  } = props;
  // console.log('show is...', show);

  const inputRef = useRef();

  const theme = useTheme();

  const elWidth = `${size || "100"}%`;

  const errorClass =
    formik?.errors?.[name] && formik?.touched?.[name] ? "error" : "";

  async function onFileChange(file) {
    //if (!file) return;
    //Can't do this if we want to be able to delete the file

    await formik?.setFieldValue(`${name}`, file, true);
    await formik?.validateForm();
  }

  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  );

  return (
    <div css={cssFormElements(theme, elWidth).fileUpload}>
      {getLabel}

      <input
        name={name}
        accept={accept}
        id={name}
        type="file"
        ref={inputRef}
        // className={errorClass}
        hidden
        onChange={(e) => onFileChange(e.target.files[0])}
      />

      {displaytype === "image" && (
        <DragAndDrop
          className={errorClass}
          accept={accept}
          onChange={onFileChange}
          onClick={() => inputRef.current.click()}
          file={formik?.values?.[name]}
          placeholder={formik?.values?.[`${name}Url`] || placeholder}
          show={show}
        />
      )}
      {displaytype === "pdf" && (
        <DragAndDropPdf
          className={errorClass}
          accept={accept}
          onChange={onFileChange}
          onClick={() => inputRef.current.click()}
          file={formik.values[name]}
          placeholder={formik.values[`${name}Url`] || placeholder}
          show={show}
        />
      )}
    </div>
  );
}
