/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router";

import { css } from "./css";
import { useQuery } from "hooks";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import React, { useState } from "react";
import MemberPersonalInfo from "./sections/member-personal-info";
import MemberGuardians from "./sections/member-guardians";
import MemberHorses from "./sections/member-horses";
import MemberAwards from "./sections/member-awards";
import ModalDeleteMember from "./modals/modal-delete-member";
import ModalAddGuardian from "./modals/modal-add-guardian";
import MemberModalHorse from "../members/member-modal-horse";
import MemberModal from "../members/member-modal";
import ResetPasswordModal from "components/user-page-template/user-reset-password-modal";
import UserRemoveModal from "components/user-page-template/user-remove-modal";

const Member = () => {
  const navigate = useNavigate();

  const { memberId } = useParams();

  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [showAddGuardianModal, setShowAddGuardianModal] = useState(false);
  const [showAddHorseModal, setShowAddHorseModal] = useState(false);
  const [showEditMemberModal, setShowEditMemberModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [activeHorse, setActiveHorse] = useState(false);

  const {
    data: memberData,
    isLoading,
    refetch,
  } = useQuery(`/members/${memberId}`, {
    cacheTime: 0,
    enabled: !!memberId,
  });

  const theme = useTheme();

  const btnDeleteMember = () => (
    <Button
      label="Delete member"
      color="danger"
      variant="link"
      onClick={() => {
        setShowDeleteMemberModal(true);
      }}
    />
  );

  const btnAddGuardian = () => (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add guardian"
      color="primary"
      variant="outlined"
      onClick={() => {
        setShowAddGuardianModal(true);
      }}
    />
  );

  const btnAddHorse = () => (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add horse"
      color="primary"
      variant="outlined"
      onClick={() => {
        setShowAddHorseModal(true);
      }}
    />
  );

  const btnEditMember = (formik) => (
    <Button
      icon={<Icon icon="Edit" />}
      iconPos="left"
      label="Edit member"
      color="primary"
      onClick={() => {
        setShowEditMemberModal(true);
      }}
    />
  );

  const actions = [
    btnDeleteMember(),
    btnAddGuardian(),
    btnAddHorse(),
    btnEditMember(),
  ];

  const actionsBlock = actions
    ? actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))
    : null;

  const titleBlock = (
    <TitleBlock>
      <div>
        <Button
          icon={<Icon icon="ChevronLeft" />}
          iconPos="left"
          label="Return to members"
          onClick={() => navigate(-1)}
          color="primary"
          variant="link"
        />
      </div>

      {actionsBlock}
    </TitleBlock>
  );
  const memberPersonalInfo = (
    <MemberPersonalInfo
      setShowModal={setShowResetPasswordModal}
      memberData={memberData}
    />
  );

  const guardians = (
    <MemberGuardians memberData={memberData} refetch={refetch} />
  );

  const horses = (
    <MemberHorses
      memberData={memberData}
      setActiveHorse={setActiveHorse}
      setShowModal={setShowAddHorseModal}
    />
  );

  const awards = <MemberAwards memberData={memberData} />;

  const modals = (
    <>
      {showDeleteMemberModal && (
        <UserRemoveModal
          setShowModal={setShowDeleteMemberModal}
          user={memberData?.member?.user}
          onConfirm={() => refetch()}
          userRouteAppend="hard-delete"
          userRoute="/members"
        />
      )}
      {showAddGuardianModal && (
        <ModalAddGuardian onHide={setShowAddGuardianModal} refetch={refetch} />
      )}
      {showAddHorseModal && (
        <MemberModalHorse
          setShowModal={setShowAddHorseModal}
          memberId={memberId}
          activeHorse={activeHorse}
        />
      )}
      {showEditMemberModal && (
        <MemberModal
          setShowModal={setShowEditMemberModal}
          activeMember={memberData?.member}
        />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          setShowModal={setShowResetPasswordModal}
          memberEmail={memberData?.member?.user?.email || "no email on file"}
        />
      )}
    </>
  );

  return (
    <div css={css(theme)}>
      {titleBlock}
      {memberPersonalInfo}
      {guardians}
      {horses}
      {awards}
      {modals}
    </div>
  );
};

export default Member;
