/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { css } from "./css";

export default function Show(props) {
  const { showClass, lots } = props;

  const theme = useTheme();

  return (
    <div css={css(theme).showTitleInput}>
      <div css={css(theme).showTitleInput.title}>
        {showClass.class_number || showClass.manual_class_number || ""} -{" "}
        {showClass.long_name}
      </div>

      <div css={css(theme).showTitleInput.subStat}>
        Total riders: {showClass?.registrants?.length || 0}
      </div>
      <div css={css(theme).showTitleInput.subStat}>{`Lots: ${lots}`}</div>
      {/* <Button variant="crystal success" onClick={() => setEditName(!editName)}>
        <FaEdit />
      </Button> */}
    </div>
  );
}
