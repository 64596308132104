/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import FormListSelect from "./form-list-select";
import FormListText from "./form-list-text";

import { cssFormElements } from "components/form-element/css";

export default function FormList(props) {
  const {
    formik,
    name,
    label,
    type = "text",
    options = [],
    placeholder,
    listItemLabel,
    required = false,
    disabled,
  } = props;

  const theme = useTheme();

  const errorClass = formik?.errors[name] ? "error" : "";

  const assignVal = (index, val) => {
    const opts = [
      ...formik.values[name].slice(0, index),
      val,
      ...formik.values[name].slice(index + 1),
    ];

    formik.setFieldValue(name, opts);
  };

  const blurVal = (index, val) => {
    if (!val) {
      const opts = [
        ...formik.values[name].slice(0, index),
        ...formik.values[name].slice(index + 1),
      ];

      formik.setFieldValue(name, opts);
    }
  };

  const valsArr = formik?.values[name] ? [...formik.values[name]] : [];
  if (!disabled) {
    valsArr.push("");
  }

  const inputDivs = valsArr.map((val, index, arr) => {
    const fieldName = `${name}-${index}`;

    const inputEl =
      type === "select" ? (
        <FormListSelect
          value={val}
          options={options}
          selected={formik.values[name]}
          name={fieldName}
          errorClass={errorClass}
          placeholder={placeholder}
          onChange={(evt) => assignVal(index, evt.target.value)}
          // onBlur={(evt) => blurVal(index, evt.target.value)}
          onRemove={() => blurVal(index)}
          noActions={index === arr.length - 1}
          disabled={disabled}
        />
      ) : (
        <FormListText
          name={fieldName}
          label={listItemLabel ? `${index + 1}.` : ""}
          value={val}
          onChange={(evt) => assignVal(index, evt.target.value)}
          // onBlur={(evt) => blurVal(index, evt.target.value)}
          onRemove={() => blurVal(index)}
          noActions={index === arr.length - 1}
          disabled={disabled}
        />
      );

    return (
      <div
        key={fieldName}
        css={cssFormElements(theme).formList.actions.inputDivs.inputEl}
      >
        {inputEl}
        {/*
        <div className={`form-group-item label-top stack`}>{inputEl}</div>
        <div
          className="form-group-item label-top stack"
          style={{
            width: 33,
          }}
        >
          <button
            onClick={(evt) => {
              evt.preventDefault();
              if (index < formik?.values[name]?.length) {
                let opts = formik?.values[name] || [];
                opts.splice(index, 1);
                formik.setFieldValue(name, opts);
              }
            }}
            className={`btn btn-outline-success`}
            id={index}
            style={{
              height: 28,
              maxWidth: "none",
              padding: 0,
              width: "100%",
            }}
          >
            {index < formik?.values[name]?.length ? "-" : "+"}
          </button>
        </div>
        */}
      </div>
    );
  });

  return (
    <div css={[cssFormElements(theme), cssFormElements(theme).formList]}>
      <label htmlFor={name}>
        {label}
        {required && <span>*</span>}
      </label>
      <div css={cssFormElements(theme).formList.actions}>
        <div css={cssFormElements(theme).formList.actions.inputDivs}>
          {inputDivs}
        </div>
      </div>
    </div>
  );
}
