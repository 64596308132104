/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef, useMemo } from "react";

import { useQuery } from "hooks";
import { appColors } from "emotion/utils";

import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import Accordion from "components/accordion/accordion";
import ButtonIcon from "components/button-icon/button-icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";

import BaseAwardModal from "./base-award-modal";
import BaseAwardSection from "./base-award-section";
import BaseAwardArchiveModal from "./base-award-archive-modal";

import { css } from "./css";

export default function BaseAwards() {
  const [showBaseAwardModal, setShowBaseAwardModal] = useState(false);
  const [showArchiveBaseAwardModal, setShowArchiveBaseAwardModal] =
    useState(false);
  const [activeBtnGroup, setActiveBtnGroup] = useState("active");

  const theme = useTheme();

  const [activeBaseAward, setActiveBaseAward] = useState(null);
  const activePrimaryAward = useRef(false);
  const activeMedalAward = useRef(false);

  const uri = useMemo(() => {
    const url = new URL(document.baseURI);
    url.searchParams.append(
      "archived",
      activeBtnGroup === "active" ? "false" : "true",
    );
    return `/base-awards${url.search}`;
  }, [activeBtnGroup]);

  const { data, refetch } = useQuery(uri);

  function onCreateEdit(primary, medal, baseAward) {
    setActiveBaseAward(baseAward);
    activePrimaryAward.current = primary;
    activeMedalAward.current = medal;
    setShowBaseAwardModal(true);
  }

  function onArchive(data, archive) {
    setActiveBaseAward({ data, archive });
    setShowArchiveBaseAwardModal(true);
  }

  const filterBtnGroup = (
    <FilterBtnGroup
      options={["Active", "Archived"]}
      activeBtnGroup={activeBtnGroup}
      setActiveBtnGroup={setActiveBtnGroup}
    />
  );

  const btnAddAward = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add award"
      onClick={() => onCreateEdit(true, false)({})}
    />
  );

  const actions = [btnAddAward];

  const actionsBlock = actions.map((btn, index) => (
    <React.Fragment key={index}>{btn}</React.Fragment>
  ));

  const titleBlock = <TitleBlock>{actionsBlock}</TitleBlock>;

  const contentBlock = (
    <>
      <BaseAwardSection
        label="Placements"
        buttonLabel="+ Add Placement"
        data={data?.data.filter((a) => a.primary && !a.medal)}
        onCreate={() => {
          onCreateEdit(true, false);
        }}
        onEdit={() => {
          onCreateEdit(true, false);
        }}
        onArchive={onArchive}
        activeBtnGroup={activeBtnGroup}
      />

      <BaseAwardSection
        label="Danishes"
        buttonLabel="+ Add Danish"
        data={data?.data.filter((a) => !a.primary && !a.medal)}
        onCreate={() => {
          onCreateEdit(false, false);
        }}
        onEdit={() => {
          onCreateEdit(false, false);
        }}
        onArchive={onArchive}
        activeBtnGroup={activeBtnGroup}
      />

      <BaseAwardSection
        label="Medals"
        buttonLabel="+ Add Medal"
        data={data?.data.filter((a) => a.medal)}
        onCreate={() => {
          onCreateEdit(false, true);
        }}
        onEdit={() => {
          onCreateEdit(false, true);
        }}
        onArchive={onArchive}
        activeBtnGroup={activeBtnGroup}
      />
    </>
  );

  const getIcon = (el) =>
    el?.medal ? (
      <Icon icon="Medal" stroke={appColors?.grays?.[0]} />
    ) : !el?.primary ? (
      <Icon icon="Danish" stroke={null} />
    ) : (
      <Icon icon="Placing" stroke={null} />
    );

  const getAwardEls = (data) => {
    // console.log('BASE AWARDS data:', data);

    return (
      <div css={css(theme).awardEls}>
        {data?.map((el) => {
          return (
            <div key={el?.id} css={css(theme).awardEls.awardEl}>
              <div css={css(theme).awardEls.awardEl.title}>
                {getIcon(el)}
                {el?.name}
              </div>

              <CustomDivTableActions>
                <ButtonIcon
                  icon={<Icon icon="Archive" />}
                  onClick={() => {
                    alert("archive functionality");
                    // onArchive();
                  }}
                />

                <ButtonIcon
                  icon={<Icon icon="Edit" />}
                  onClick={() => {
                    // alert("edit functionality")
                    onCreateEdit(true, false, el);
                  }}
                />
              </CustomDivTableActions>
            </div>
          );
        })}
      </div>
    );
  };

  const awardOptions = [
    {
      name: "Placings",
      content: getAwardEls(data?.data.filter((a) => a.primary && !a.medal)),
    },
    {
      name: "Danish Awards",
      content: getAwardEls(data?.data.filter((a) => !a.primary && !a.medal)),
    },
    {
      name: "Medals",
      content: getAwardEls(data?.data.filter((a) => a.medal)),
    },
  ];

  const accordion = <Accordion tabs={awardOptions} />;

  const content = (
    <>
      {titleBlock}
      {accordion}

      {/* {contentBlock}   */}
    </>
  );

  const modals = (
    <>
      {showBaseAwardModal && (
        <BaseAwardModal
          setShowModal={setShowBaseAwardModal}
          baseAward={activeBaseAward}
          primary={activePrimaryAward.current}
          medal={activeMedalAward.current}
          onSuccess={refetch}
        />
      )}

      {showArchiveBaseAwardModal && (
        <BaseAwardArchiveModal
          setShowModal={setShowArchiveBaseAwardModal}
          activeBtnGroup={activeBtnGroup}
          baseAward={activeBaseAward}
          onAccept={refetch}
        />
      )}
    </>
  );

  return (
    <div css={css(theme)}>
      {content}
      {modals}
    </div>
  );
}
