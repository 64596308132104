/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { useState, useRef } from "react";
import moment from "moment";

import { useToast, useQuery } from "hooks";
import { useMutation } from "hooks";
import { capitalizeFirstLetter } from "utils";
import Client from "client";

import CustomDivTable from "components/custom-table/custom-div-table";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";

import AdminModal from "./admin-modal";
import React from "react";
import ResetPasswordModal from "components/user-page-template/user-reset-password-modal";
import UserRemoveModal from "components/user-page-template/user-remove-modal";

export default function Admins() {
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState(false);
  const [, setAdminRemoveModal] = useState(false);
  const [activeAdmin, setActiveAdmin] = useState(null);

  const activeUser = useRef(null);

  const { data, refetch } = useQuery("/admins");

  const theme = useTheme();

  function editAdmin(data) {
    activeUser.current = data;
    setShowAdminModal(true);
  }

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "edit-user",
    mutationFn: (data) => {
      if (data?.id) {
        return Client.put(`/admins/${data.id}`, data);
      }
    },
    onSuccess: (values) => {
      setAdminRemoveModal(false);
      refetch();
    },
  });

  // How are we going to go about admins being removed?
  // function removeAdmin(data, archive) {
  //   data.banned = archive;
  //   const serverData = {
  //     firstName: data.first_name,
  //     lastName: data.last_name,
  //     email: data.email,
  //     birthAt: data.birth_at,
  //     banned: data.banned,
  //     id: data.id,
  //     createdAt: data.created_at,
  //   };
  //   submitRequest(serverData);
  // }

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => {
        return `${capitalizeFirstLetter(
          data.first_name,
        )} ${capitalizeFirstLetter(data.last_name)}`;
      },
      display: "Admin name",
      sortable: false,
    },
    {
      name: "email",
      default: () => "",
      valFn: (data) => `${data.email ? data.email : "--"}`,
      display: "Email",
    },
    {
      name: "phone",
      default: () => "",
      valFn: (data) => `${data.phone ? data.phone : "--"}`,
      display: "Phone",
    },
    {
      name: "dateAdded",
      default: () => "",
      valFn: (data) =>
        `${
          data.created_at ? moment(data.created_at).format("MM/DD/YYYY") : "--"
        }`,
      display: "Date Added",
      sortable: false,
      align: "right",
      alignHeader: "right",
    },
    {
      name: "status",
      default: () => "",
      valFn: (data) => `${data.status ? data.status : "--"}`,
      display: "Status",
      sortable: false,
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            onClick={() => {
              // setAdminRemoveModal(true)
              // alert("remove admin functionality");
              activeUser.current = it;
              setShowRemoveAdminModal(true);
            }}
          />
          <ButtonIcon
            icon={<Icon icon="Refresh" />}
            onClick={() => {
              activeUser.current = it;
              setShowResetPasswordModal(true);
            }}
          />
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => editAdmin(it)}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  const handleCreate = () => {
    activeUser.current = null;
    setShowAdminModal(true);
  };

  const btnCreate = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add admin"
      onClick={handleCreate}
    />
  );

  const actions = [btnCreate];

  const titleBlock = (
    <TitleBlock>
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </TitleBlock>
  );

  const modals = (
    <>
      {showAdminModal && (
        <AdminModal
          setShowModal={setShowAdminModal}
          user={activeUser.current}
          onSuccess={refetch}
        />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          setShowModal={setShowResetPasswordModal}
          // memberEmail={data?.data?.email}
          user={activeUser.current}
          memberEmail={activeUser.current?.email}
        />
      )}
      {showRemoveAdminModal && (
        <UserRemoveModal
          setShowModal={setShowRemoveAdminModal}
          user={activeUser.current}
          nameOfUser="admin"
          userRoute="/admins"
          onSuccess={refetch}
        />
      )}
    </>
  );

  return (
    <>
      {titleBlock}

      <CustomDivTable
        // title="Admins"
        columns={columns}
        rows={data?.data}
        rowCount={10}
        // selectionMode="single"
        // onSelect={(it) => editAdmin(it)}
        filterKeys={["first_name", "last_name", "email"]}
        showSearch={false}
        emptyMessage="No Admins Found"
      />

      {modals}
    </>
  );
}
