import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

import CustomDivTable from "components/custom-table/custom-div-table";
import FormItemGroup from "components/form-item-group/form-item-group";
import Button from "components/button/button";

const ShowRegistrantsPanelDetails = ({ dataIn, handleManageClasses }) => {
  const navigate = useNavigate();

  const data = cloneDeep(dataIn);

  if (data.show_classes) {
    data.show_classes = data.show_classes
      .filter((dat) => dat.show_class.show_id === dataIn.show.id)
      .sort((a, b) => {
        return moment(a.start_at).valueOf() === moment(b.start_at).valueOf()
          ? (parseInt(a.class_number.match(/\d/g).join(""), 10) || 0) <=
            (parseInt(b.class_number.match(/\d/g).join(""), 10) || 0)
            ? 1
            : -1
          : moment(a.start_at).valueOf() < moment(b.start_at).valueOf()
          ? 1
          : -1;
      });
  }

  const columns = [
    {
      name: "name",
      valFn: (data) => {
        return data?.show_class.long_name || "--";
      },
      display: "Class",
    },
    {
      name: "class_number",
      valFn: (data) => data.class_number || "--",
      display: "Class #",
      align: "right",
      alignHeader: "right",
    },
    {
      name: "horse",
      valFn: (data) => data.animal.name || "--",
      display: "Horse",
    },
    {
      name: "arena",
      valFn: (data) => data.arena.name || "--",
      display: "Arena",
    },
    {
      name: "date",
      valFn: (data) =>
        moment(data.show_class.start_at).format("ddd DD") || "--",
      display: "Date",
      align: "right",
      alignHeader: "right",
    },
    // {
    //   name: "payment",
    //   valFn: (data) =>
    //     moment(data.show_class.start_at).format("ddd, D") || "--",
    //   display: "Date",
    // },
  ];

  const handleGoToUser = () => {
    // navigate(`/admin/users/members?id=${data.member_id}`);
    navigate(`/admin/users/members/${data.member_id}`);
  };

  return (
    <>
      <FormItemGroup
        title="Registered Classes"
        stacked={true}
        padx={0}
        pady={0}
        bgColor="#ffffff"
      >
        <CustomDivTable
          columns={columns}
          rows={data.show_classes}
          emptyMessage="No Classes Found"
        />

        <div style={{ justifyContent: "flex-end" }}>
          <Button
            label="View member information"
            onClick={() => handleGoToUser()}
            variant="outlined"
          />
          <Button
            label="Manage Classes"
            onClick={() => handleManageClasses()}
          />
        </div>
      </FormItemGroup>
    </>
  );
};

export default ShowRegistrantsPanelDetails;
