import React from "react";
import css from "./arena-tile.module.scss";

const ClassTile = (props) => {
  const { data, selected, onClick } = props;

  const isInProgress =
    !!data.time_scores_available && !data.time_scores_announced;
  const isComplete = !!data.time_scores_announced;

  const scoreShare = true;

  const cssTile = `${css.tile} ${isComplete ? css.complete : ""} ${
    scoreShare === true ? css.shared : ""
  } ${selected ? css.selected : ""}`;

  const cssStatus = `${css.title} ${isComplete ? css.titleComplete : ""}`;

  const sharing = scoreShare === true ? "Scores Shared" : "Scores Not Shared";
  const status = isComplete
    ? "Complete"
    : isInProgress
    ? "Scores submitted"
    : "Upcoming";

  return (
    <button onClick={() => onClick(data.id)} className={cssTile}>
      <div className={css.leftCont}>
        <div className={css.title}>{data.short_name}</div>
        <div className={css.longname}>{data.long_name}</div>
      </div>
      <div className={css.rightCont}>
        <b className={cssStatus}>{status}</b>
        {isComplete && <div className={css.longname}>{sharing}</div>}
      </div>
    </button>
  );
};
export default ClassTile;
