import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import moment from "moment";
import IconChevronRight from "components/icons/icon-chevron-right";

import { useConfirmationModal } from "confirmation-modal/confirmation-modal";
import CustomButton from "components/custom-button/custom-button";
import CustomDivTable from "components/custom-table/custom-div-table";
import MemberModalHorse from "./member-modal-horse";
import { useQuery } from "hooks";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { fullWidth } from "emotion/utils";

export default function HorseModal({
  setShowModal = () => {},
  showModal = true,
  activeMember,
  onSuccess = () => {},
  onClose = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal(onClose);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalBodyHeight, setModalBodyHeight] = useState(0);
  const [member, setMember] = useState(null);

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      const modalBody = document.querySelector(".modal-body");
      if (modalDialog) {
        setModalHeight(modalDialog.offsetHeight);
      }
      if (modalBody) {
        setModalBodyHeight(modalBody.offsetHeight);
      }
    }, 500); // delay
  };

  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  ///console.log(activeMember)

  const { isLoading, refetch } = useQuery(`/members/${activeMember?.id}`, {
    onSuccess: (res) => setMember(res?.member),
    cacheTime: 0,
    enabled: !!activeMember?.id,
  });

  useEffect(() => {
    //console.log(member);
    calculateModalHeight();
  }, []);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDefaultMessage, setIsDefaultMessage] = useState(true);
  const [customMessage, setCustomMessage] = useState(null);
  const [showHorseModal, setShowHorseModal] = useState(false);
  const [activeHorse, setActiveHorse] = useState(null);
  const [activeHorseUse, setActiveHorseUse] = useState(null);

  const conditionalClose = () => {
    if (isFormDirty) {
      setCustomMessage();
      setIsDefaultMessage(false);
      handleExit();
    } else {
      setCustomMessage(null);
      setIsDefaultMessage(true);
      onClose();
      setShowModal(false);
    }
  };

  // const handleClose = () => [setShowModal(false)];

  const columns = [
    {
      name: "name",
      valFn: (dataHorses) => {
        return dataHorses?.animal?.name || "--";
      },
      display: "Name",
    },
    {
      name: "age",
      valFn: (dataHorses) => {
        let years = moment().diff(dataHorses?.animal?.birthday, "years");
        let months = moment().diff(dataHorses?.animal?.birthday, "months");
        let days = moment().diff(dataHorses?.animal?.birthday, "days");

        let span = years ? " yrs" : months ? " months" : days ? " days" : "";
        return (years || months || days || "--") + span;
      },
      display: "Age",
    },
    {
      name: "breed",
      valFn: (dataHorses) => {
        return dataHorses?.animal?.breed || "--";
      },
      display: "Breed",
    },
    {
      name: "end_at",
      valFn: (dataHorses) => {
        return dataHorses?.end_at ? "No" : "Yes";
      },
      display: "Visible to rider",
    },
    {
      name: "go",
      valFn: () => <IconChevronRight />,
      display: "",
      noSort: true,
      actionCol: true,
    },
    // {
    //   name: "docs",
    //   valFn: (dataHorses) => {
    //     return (
    //       <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
    //         {dataHorses?.animal.animal_document &&
    //         dataHorses?.animal.animal_document.length > 0 ? (
    //           <button
    //             className="btn btn-link"
    //             onClick={() => console.log("horse docs functionality")}
    //           >
    //             {/* TODO should show all? */}
    //             {dataHorses?.animal?.animal_document[0].url?.substring(0, 20)}
    //           </button>
    //         ) : (
    //           <></>
    //         )}
    //       </div>
    //     );
    //     // return dataHorses?.docs || '--';
    //   },
    //   display: "Documentation",
    // },
  ];

  const onSelectIt = (it) => {
    setActiveHorseUse(it);
    setActiveHorse(it.animal);
    setShowHorseModal(true);
  };

  const headerTitle = `${
    activeMember
      ? `Horses for ${capitalize(
          activeMember?.user.first_name.trim(),
        )} ${capitalize(activeMember?.user.last_name.trim())}`
      : "Create Member"
  }`;

  const btnAddHorse = <Button label="Add a Horse" onClick={onSelectIt} />;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const footerButtons = [btnCancel, btnAddHorse];

  return (
    <>
      <CustomModal
        headerTitle={headerTitle}
        footerButtons={footerButtons}
        onHide={conditionalClose}
        size="md"
      >
        <div style={{ ...fullWidth() }}>
          <CustomDivTable
            columns={columns}
            rows={member?.use_animals}
            selectionMode="single"
            onSelect={(it) => {
              onSelectIt(it);
            }}
            emptyMessage="No Horses Found"
            // filterKeys={["name", "email"]}
            // showSearch={false}
          />
        </div>
      </CustomModal>

      {showHorseModal && (
        <MemberModalHorse
          setShowModal={setShowHorseModal}
          activeHorse={activeHorse}
          onSuccess={refetch}
          onClose={onClose}
          memberId={activeMember?.id}
          horseUseData={activeHorseUse}
        ></MemberModalHorse>
      )}
    </>
  );
}
