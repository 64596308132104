/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useRef, useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form } from "formik";
import debounce from "lodash/throttle";
import * as Yup from "yup";

import Client from "client";

import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import Button from "components/button/button";

import { css } from "./css";

let award = null;

export default function BaseAwardModal({
  setShowModal = () => {},
  showModal = true,
  baseAward,
  primary,
  medal,
  onSuccess = () => {},
}) {
  console.log("baseAward", baseAward);

  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 10); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  const toastRef = useRef();
  const formRef = useRef();

  const [nameDuped, setNameDuped] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [awardType, setAwardType] = useState("");

  const theme = useTheme();

  const conditionalClose = () => {
    if (isFormDirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const memberToFormik = () => {
    return {
      name: baseAward?.name || "",
      //image: baseAward?.image?.link || null,
      points: baseAward?.points || "0",
      primary: baseAward?.primary || false,
      medal: baseAward?.medal || false,
      color: baseAward?.color || "#000000",
      awardType: baseAward?.primary
        ? baseAward?.medal
          ? "medal"
          : "placing"
        : "danish",
    };
  };
  const initialValues = memberToFormik();

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "create-edit-base-award",
    mutationFn: async (data) => {
      const newData = {
        ...data,
        points: parseInt(data.points || 0),
        primary:
          data.awardType === "placing" || data.awardType === "medal"
            ? true
            : false,
        medal: data.awardType === "medal" ? true : false,
      };

      return baseAward?.id
        ? Client.put(`/base-awards/${baseAward.id}`, newData)
        : Client.post("/base-awards", newData);
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values?.body);
    },
    onError: (error) =>
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
        life: 3000,
      }),
  });

  const checkForDupeName = async (evtOrName) => {
    let name = evtOrName?.target
      ? evtOrName?.target.value?.trim()
      : evtOrName?.trim();
    if (!name) return;

    const url = new URL(document.baseURI);
    url.searchParams.append("name", name);
    const ret = await Client.get(`/base-award${url.search}`);
    if (ret) {
      if (!award || ret.id !== award?.id) {
        //Trigger error, there is another award with this name
        setNameDuped(true);
        formRef?.current?.getFieldHelpers("name").setTouched(true);
        return true;
      } else {
        //The name matched our own award
        setNameDuped(false);
      }
    } else {
      //OK, remove any error state for this field
      setNameDuped(false);
    }
    //validate(refFormik.values);
  };

  const throttled = debounce(checkForDupeName, 1000, { leading: true });

  React.useEffect(() => {
    return () => {
      throttled.cancel();
    };
  }, []);

  const onSubmit = (values) => {
    submitRequest(values);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  award = baseAward;

  let awardLabel = "Award";
  if (primary) {
    awardLabel = "Placement";
  } else if (medal) {
    awardLabel = "Medal";
  } else {
    awardLabel = "Danish";
  }

  const headerTitle = `${baseAward === null ? `Edit award` : `Create award`}`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const btnCreate = (formik) => (
    <Button
      type="submit"
      color="primary"
      label="Save"
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
      disabled={
        Object.values(formik.errors).length ||
        !Object.values(formik.touched).length
      }
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  const awardEls = [
    {
      id: "placing",
      name: "awardType",
      label: "Placing",
      icon: "Placing",
    },
    {
      id: "danish",
      name: "awardType",
      label: "Danish award",
      icon: "Danish",
    },
    {
      id: "medal",
      name: "awardType",
      label: "Medal",
      helpertext: "Bronze, Silver, Gold, and Platinum are included by default",
      icon: "Medal",
    },
  ];

  const handleChange = (e) => {
    console.log("e", e.target.value);
    formRef.current.handleChange(e);
    setAwardType(e.target.value);
  };

  const handleChangeComplete = (e) => {
    e.name = "color";
    e.id = "color";
    e.value = e.hex;
    e.target = e;
    formRef.current.handleChange(e);
  };

  const radioButtonGroup = (els, formik) => (
    // <RadioButtonGroup els={els} onChange={handleChange} formik={formik} />
    <div css={css(theme).radioBtns}>
      {els.map((el) => (
        <FormElement
          key={el?.id}
          element="RadioButton"
          id={el?.id}
          name={el?.name}
          label={el?.label}
          helpertext={el?.helpertext}
          required
          value={el?.id}
          onChange={(e) => handleChange(e)}
          checked={formik.values["awardType"] === el?.id}
          icon={el?.icon}
        />
      ))}
    </div>
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={Yup.object({
          name: Yup.string()
            .label("Name")
            .test("name_has_value", "Name is required", (value) => {
              return value && value.trim() ? true : false;
            })
            .test("name_duped", "Award name already in use", (value) => {
              return !nameDuped;
            }),
          points: Yup.number().label("Points").min(0).max(100),
          awardType: Yup.string().label("Award Type is required"),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(memberToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
            >
              {radioButtonGroup(awardEls, formik)}

              <FormElement
                element="ColorPicker"
                id="color"
                name="color"
                formik={formik}
                color={formik.values.color}
                onChangeComplete={(e) => handleChangeComplete(e)}
              />

              <FormElement
                element="InputText"
                id="name"
                name="name"
                label="Name"
                placeholder="Name your award"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                testChange={throttled}
                required
              />

              {/* <FormTextInput
                name="points"
                label="Points*"
                formik={formik}
                min={0}
                max={100}
                number
              />
              {formik.touched?.points && formik.errors.points && (
                <span className="form-error-msg">{formik.errors.points}</span>
              )} */}
              <FormElement
                element="InputText"
                id="points"
                name="points"
                label="Points (optional)"
                placeholder="How many points is this award worth?"
                formik={formik}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.points}
              />
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
      />
    </>
  );
}
