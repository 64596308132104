/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { css } from "../css";
import { capitalizeFirstLetter, formattedPhone } from "utils";

import Accordion from "components/accordion/accordion";
import CustomDivTable from "components/custom-table/custom-div-table";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import Icon from "components/icon/icon";
import Tag from "components/tag/tag";

import { useMutation } from "../../../../hooks/use-mutation";
import Client from "../../../../client";

const MemberGuardians = (props) => {
  const theme = useTheme();

  const { memberData, refetch } = props;

  const { mutate: removeCustodian } = useMutation({
    mutationFn: (data) =>
      Client.put(`/members/${memberData?.member?.id}/custodian/remove`, data),
    onSuccess: () => {
      refetch();
    },
  });

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => {
        return `${capitalizeFirstLetter(
          data?.custodian?.user.first_name || data?.first_name,
        )} ${capitalizeFirstLetter(
          data?.custodian?.user.last_name || data?.last_name,
        )}`;
      },
      display: `Guardian name`,
    },
    {
      name: "email",
      default: () => "",
      valFn: (data) => data?.email || data?.custodian?.user?.email,
      display: "Email",
    },
    {
      name: "phone",
      default: () => "",
      valFn: (data) =>
        data?.custodian?.user?.phone
          ? formattedPhone(data.custodian?.user.phone)
          : "--",
      display: "Phone",
    },
    {
      name: "emergencyContact",
      default: () => "",
      valFn: (data) =>
        data?.user?.emergency_contact ? <Tag tag="Yes" /> : <Tag tag="No" />,
      display: "Emergency contact",
    },
    {
      name: "verified",
      default: () => "",
      valFn: (data) => (data?.custodian ? "Verified" : "Not verified"),
      display: "Verified",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Trash" />}
            onClick={() =>
              removeCustodian({
                email: it.email,
                custodianId: it?.custodian?.id,
              })
            }
          />
          {/*<ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => alert("edit member guardian functionality")}
          />*/}
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  const rows =
    memberData?.member?.member_custodians?.length > 0
      ? [...memberData?.member?.member_custodians]
      : [];

  if (memberData) {
    rows.push(...memberData?.member.custodian_invitations);
  }

  const table = (
    <CustomDivTable
      columns={columns}
      rows={rows}
      emptyMessage="No guardians found."
    />
  );

  const guardianTabs = [
    {
      icon: "UserSquare",
      name: "Guardians",
      content: table,
    },
  ];

  return (
    <div css={css(theme).guardians}>
      <Accordion tabs={guardianTabs} />
    </div>
  );
};

export default MemberGuardians;
