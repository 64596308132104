import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import moment from "moment";

import Client from "client";
import { useQuery } from "hooks";

import FormDateInput from "components/form-date-input";
import FormSelect from "components/form-select";

import { dateAsServerString } from "utils";
import { FormErrorMessage } from "validation/form-error-message";

import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import CustomModal from "components/custom-modal/custom-modal";
import Button from "components/button/button";
import { FormElement } from "components/form-element/form-element";
import { fullWidth } from "emotion/utils";

export const showType = {
  PERFORMANCE: "Performance",
  FAIR: "Fair",
};

export default function ShowModal({
  setShowModal = () => {},
  ShowModal = true,
  show,
  onSuccess = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal();
  const [modalHeight, setModalHeight] = useState(0);

  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      if (modalDialog) {
        console.log("This Modal Height = " + modalDialog.offsetHeight + "px");
        setModalHeight(modalDialog.offsetHeight);
      }
    }, 50); // delay
  };
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);

  const queryClient = useQueryClient();
  const toastRef = useRef();

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "create-edit-show",
    networkMode: "always",
    mutationFn: async (data) => {
      const dateKeys = [
        "registrationOpenAt",
        "postEntryAt",
        "startAt",
        "endAt",
      ];
      dateKeys.forEach((key) => {
        data[key] = dateAsServerString(data[key]);
      });
      data.preentryCost = data.preentryCost.replace("$", "");
      data.postentryCost = data.postentryCost.replace("$", "");
      data.locationId = data.locationId;
      data.showType = data.showType;

      return show?.id
        ? Client.put(`/shows/byId/${show?.id}`, data)
        : Client.post("/shows", data);
    },
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values.body);
    },
    onError: (error) =>
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: error.toString(),
        life: 3000,
      }),
  });

  const { data: locations, isLoading: isLocationsLoading } =
    useQuery("/locations");

  useEffect(() => {
    queryClient.invalidateQueries({ queryFn: ["locations-for-show"] });
  }, [show, queryClient]);

  const onSubmit = async (values) => submitRequest(values);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const handleClose = () => [setShowModal(false)];

  const conditionalClose = () => {
    if (isFormDirty) {
      handleExit();
    } else {
      setShowModal(false);
    }
  };

  const showToFormik = () => {
    return {
      name: show ? show.name : "",
      locationId: show ? show.location.id : null,
      registrationOpenAt: show ? show.registration_open_at : "",
      preentryCost: show ? show.pre_entry_cost : "",
      postentryCost: show ? show.post_entry_cost : "",
      startAt: show ? show.start_at : "",
      endAt: show ? show.end_at : "",
      showType: show ? show.show_type : "",
      description: show ? show.description : "",
      avatar: show ? show?.avatar?.link : "",
      waiver: show ? show?.waiver?.link : "",
    };
  };
  const initialValues = showToFormik();

  if (isLoading || isLocationsLoading) {
    return <div>Loading...</div>;
  }

  const headerTitle = `${show ? `Edit ${show.name}` : "Create show"}`;

  const btnCancel = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );

  const btnUpdateCreate = (formik) => (
    <Button
      label={show ? "Save changes" : "Create"}
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
      disabled={!(formik.isValid && formik.dirty)}
    />
  );

  const footerButtons = (formik) =>
    [btnCancel, btnUpdateCreate(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  const locationOptions = locations
    ? locations.data.map((l) => ({
        value: l.id,
        label: l.name,
      }))
    : [];

  const showTypeOptions = Object.entries(showType).map(([key, val]) => ({
    value: key,
    label: val,
  }));

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        // validate={(values) => {
        //   console.log(values);
        //   return {};
        // }}
        validationSchema={Yup.object({
          name: Yup.string().label("Name").required(),
          locationId: Yup.mixed().label("Show Location").required(),
          registrationOpenAt: Yup.date()
            .label("Show registration Open Date")
            .required()
            .test(
              "registrationAtSequence",
              "Registration must be <= Start At",
              (item, thisContext) => {
                return item <= (thisContext.parent.StartAt || item);
              },
            ),
          startAt: Yup.date()
            .label("Show Start Date")
            .required()
            .test(
              "startAtSequence",
              "Start must be >= to Registration and <= End",
              (item, thisContext) => {
                return (
                  (thisContext.parent.registrationOpenAt || item) <= item &&
                  item <= (thisContext.parent.endAt || item)
                );
              },
            ),
          endAt: Yup.date()
            .label("Show End Date")
            .required()
            .test(
              "endAtSequence",
              "End must be >= to both Start and Registration",
              (item, thisContext) => {
                return (
                  (thisContext.parent.registrationOpenAt || item) <= item &&
                  (thisContext.parent.registrationStartAt || item) <= item
                );
              },
            ),
          showType: Yup.mixed().label("Show Type").required(),
          preentryCost: Yup.string()
            .label("Pre-Entry Cost")
            .test(
              "test",
              "Pre-Entry Cost must be a number (0 is ok)",
              (data) => {
                if (!data) return false;
                const cleanedData = data.replace("$", "").trim();
                return !isNaN(cleanedData - parseFloat(cleanedData));
              },
            ),
          postentryCost: Yup.string()
            .label("Post-Entry Cost")
            .test(
              "test",
              "Post-Entry Cost must be a number (0 is ok)",
              (data) => {
                if (!data) return false;
                const cleanedData = data.replace("$", "").trim();
                return !isNaN(cleanedData - parseFloat(cleanedData));
              },
            ),
          //avatar: Yup.mixed().label("Show Cover Image"),
          // waiver: Yup.mixed()
          //   .label("Waiver")
          //   .test("type", "Only the .pdf format is accepted", (value) => {
          //     if (!value) return true;
          //     const retValue =
          //       value &&
          //       (value.type === "application/pdf" ||
          //         typeof value === "string" ||
          //         value instanceof String);
          //     return retValue;
          //   }),
        })}
      >
        {(formik) => {
          return (
            <Form
              onChange={(evt) => {
                const clone = structuredClone(formik.values);
                clone[evt.target.name] = evt.target.value;
                setIsFormDirty(
                  JSON.stringify(clone) !== JSON.stringify(showToFormik()),
                );
              }}
            >
              <CustomModal
                headerTitle={headerTitle}
                footerButtons={footerButtons(formik)}
                onHide={conditionalClose}
                size="md"
              >
                {/* <FormTextInput name="name" label="Name*" formik={formik} />
                <FormErrorMessage name="name" /> */}
                <FormElement
                  element="InputText"
                  id="name"
                  name="name"
                  label="Show name"
                  placeholder="Name your show"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  required
                  size="48"
                />
                <FormErrorMessage name="name" formik={formik} />

                {/* <div style={{ ...fullWidth(), maxWidth: "48%" }}> */}
                {/* <FormSelect
                    name="locationId"
                    label="Location"
                    formik={formik}
                    options={locations?.data.map((l) => ({
                      value: l.id,
                      display: l.name,
                    }))}
                    none
                    value={formik.values["locationId"]}
                    labelPosition="top"
                    required
                  />
                  <FormErrorMessage name="locationId" /> */}
                <FormElement
                  element="Dropdown"
                  id="locationId"
                  name="locationId"
                  label="Location"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.locationId}
                  options={locationOptions}
                  size="48"
                  required
                  optionValue="value"
                  optionLabel="label"
                />
                {/* </div> */}

                {/* <FormSelect
                  name="showType"
                  label="Show Type*"
                  formik={formik}
                  options={Object.entries(showType).map(([key, val]) => ({
                    value: key,
                    display: val,
                  }))}
                  none
                  value={formik.values["showType"]}
                  labelPosition="top"
                />
                <FormErrorMessage name="showType" /> */}
                <FormElement
                  element="Dropdown"
                  id="showType"
                  name="showType"
                  label="Show type"
                  placeholder="Select show type"
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values["showType"]}
                  options={showTypeOptions}
                  required
                  size="31"
                />
                <FormErrorMessage name="showType" formik={formik} />

                <FormElement
                  element="CurrencyInput"
                  id="preentryCost"
                  name="preentryCost"
                  label="Pre-entry cost"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values["preentryCost"]}
                  required
                  size="31"
                />
                <FormErrorMessage name="preentryCost" formik={formik} />

                <FormElement
                  element="CurrencyInput"
                  id="postentryCost"
                  name="postentryCost"
                  label="Post-entry cost"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values["postentryCost"]}
                  required
                  size="31"
                />
                <FormErrorMessage name="postentryCost" formik={formik} />

                {/* <FormDateInput
                  name="registrationOpenAt"
                  label="Registration date"
                  formik={formik}
                  date
                  max={
                    formik.values.postEntryAt ||
                    formik.values.startAt ||
                    formik.values.endAt
                  }
                />
                <FormErrorMessage name="registrationOpenAt" /> */}
                <FormElement
                  element="Calendar"
                  id="registrationOpenAt"
                  name="registrationOpenAt"
                  label="Registration date"
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values["registrationOpenAt"]}
                  date
                  max={
                    formik.values.postEntryAt ||
                    formik.values.startAt ||
                    formik.values.endAt
                  }
                  required
                  size="48"
                />
                <FormErrorMessage name="registrationOpenAt" formik={formik} />

                {/* <FormDateInput
                  name="postEntryAt"
                  label="Post-entry date"
                  helperText="(Leave empty if N/A)"
                  formik={formik}
                  date
                  min={formik.values.registrationOpenAt}
                  max={formik.values.startAt || formik.values.endAt}
                />
                <FormErrorMessage name="postEntryAt" /> */}
                <FormElement
                  element="Calendar"
                  id="postEntryAt"
                  name="postEntryAt"
                  label="Post-entry date"
                  onChange={formik.handleChange}
                  value={formik.values["postEntryAt"]}
                  placeholder="(Leave empty if N/A)"
                  formik={formik}
                  date
                  min={formik.values.registrationOpenAt}
                  max={formik.values.startAt || formik.values.endAt}
                  size="48"
                />

                {/* <FormDateInput
                  name="startAt"
                  label="Start Date*"
                  formik={formik}
                  date
                  min={
                    formik.values.postEntryAt &&
                    moment(formik.values.postEntryAt).format("MM/DD/YYYY") ===
                      "01/01/1970"
                      ? formik.values.postEntryAt
                      : formik.values.registrationOpenAt
                  }
                  max={formik.values.endAt}
                />
                <FormErrorMessage name="startAt" /> */}
                <FormElement
                  element="Calendar"
                  id="startAt"
                  name="startAt"
                  label="Start date"
                  formik={formik}
                  min={
                    formik.values.postEntryAt &&
                    moment(formik.values.postEntryAt).format("MM/DD/YYYY") ===
                      "01/01/1970"
                      ? formik.values.postEntryAt
                      : formik.values.registrationOpenAt
                  }
                  max={formik.values.endAt}
                  onChange={formik?.handleChange}
                  value={formik?.values["startAt"]}
                  required
                  size="48"
                />
                <FormErrorMessage name="startAt" formik={formik} />

                {/* <FormDateInput
                  name="endAt"
                  label="End Date*"
                  formik={formik}
                  date
                  min={
                    formik.values.startAt ||
                    formik.values.postEntryAt ||
                    formik.values.registrationOpenAt
                  }
                />
                <FormErrorMessage name="endAt" /> */}
                <FormElement
                  element="Calendar"
                  id="endAt"
                  name="endAt"
                  label="End date"
                  formik={formik}
                  date
                  onChange={formik?.handleChange}
                  value={formik?.values["endAt"]}
                  min={
                    formik.values.startAt ||
                    formik.values.postEntryAt ||
                    formik.values.registrationOpenAt
                  }
                  required
                  size="48"
                />
                <FormErrorMessage name="endAt" formik={formik} />

                <FormElement
                  element="InputTextarea"
                  id="description"
                  name="description"
                  label="Description (optional)"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />

                {/* <FormFileUpload
                  name="avatar"
                  label="Cover Image"
                  formik={formik}
                  placeholder="Click to Add Image"
                /> */}
                <FormElement
                  element="FileUpload"
                  id="avatar"
                  name="avatar"
                  label="Cover image (optional)"
                  formik={formik}
                  accept=".svg, .png, .pdf, .jpg, .jpeg"
                  size="48"
                  show={show}
                />

                {/* <FormFileUpload
                  name="waiver"
                  label="Waiver"
                  formik={formik}
                  accept=".pdf"
                  displayType="pdf"
                  placeholder="Click to add Waiver"
                  helperText="PDFs are accepted."
                /> */}
                <FormElement
                  element="FileUpload"
                  id="waiver"
                  name="waiver"
                  label="Waiver (optional)"
                  formik={formik}
                  accept=".pdf"
                  displaytype="pdf"
                  size="48"
                  show={show}
                />
              </CustomModal>
            </Form>
          );
        }}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
      />
    </>
  );
}
