/**@jsxImportSource @emotion/react */
import React from "react";
import * as Yup from "yup";

import { FormElement } from "components/form-element/form-element";

const SCORING_TYPES = [
  { label: "Generic", value: "GENERIC" },
  { label: "Trail", value: "TRAIL" },
  { label: "Rail", value: "RAIL" },
  { label: "Pattern", value: "PATTERN" },
];

const PLACINGS = [
  { label: "None", value: "NONE" },
  { label: "1-8", value: "ONE_EIGHT" },
  { label: "1-10", value: "ONE_TEN" },
];

export const initialValues = {
  name: "",
  alias: "",
  description: "",
  placings: {},
  scoringType: {},
  danish: false,
};

function populatePlacing(placing) {
  const v = PLACINGS.find((p) => p.value === placing);
  return v?.value;
}

function populateType(type) {
  const v = SCORING_TYPES.find((p) => p.value === type);
  return v?.value;
}

export const existingValues = (data) => ({
  name: data.name,
  alias: data.alias,
  description: data.description,
  placings: populatePlacing(data.placings),
  scoringType: populateType(data.scoring_type),
  danish: data.has_danish,
});

export const validationSchema = Yup.object({
  name: Yup.string().label("Class type name").required(),
  alias: Yup.string().label("Abbreviation").min(1).max(3).required(),
  placings: Yup.mixed().label("Type placings").required(),
  scoringType: Yup.mixed().label("Scoring type").required(),
  danish: Yup.boolean().label("Danish").optional(),
});

export const postUrl = `/event-types`;

export const body = (data) => {
  return { ...data };
};

export default function ClassTypeForm(props) {
  const { formik } = props;

  return (
    <>
      <FormElement
        element="InputText"
        id="name"
        name="name"
        label="Class type name"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.name || ""}
        required
        size="54"
      />

      <FormElement
        element="InputText"
        id="alias"
        name="alias"
        label="Abbreviation (up to 3 characters)"
        formik={formik}
        onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
        value={formik?.values?.alias}
        required
        size="43"
      />

      <FormElement
        element="Dropdown"
        id="placings"
        name="placings"
        label="Placings"
        options={PLACINGS}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.placings}
        required
      />

      <FormElement
        element="Dropdown"
        id="scoringType"
        name="scoringType"
        label="Scoring type"
        formik={formik}
        options={SCORING_TYPES}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.scoringType}
        required
      />

      <FormElement
        element="Checkbox"
        id="danish"
        name="danish"
        label="Danish Awards"
        formik={formik}
        onChange={formik.handleChange}
        checked={formik.values.danish}
      />

      <FormElement
        element="InputTextarea"
        id="description"
        name="description"
        label="Description (optional)"
      />
    </>
  );
}
