import { Form, Formik } from "formik";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import moment from "moment";

import { capitalizeFirstLetter } from "utils";
import Client from "client";
import { useQuery } from "hooks";
import { emailValid } from "validation/form";

import ConfirmationModal, {
  useConfirmationModal,
} from "confirmation-modal/confirmation-modal";
import FormBlock from "components/form-block/form-block";
import CustomButton from "components/custom-button/custom-button";

import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";
import FormSelect from "components/form-select";
import FormDateInput from "components/form-date-input";
import FormAddressBlock from "components/form-address-block/form-address-block";
import Button from "components/button/button";
import { FormErrorMessage } from "validation/form-error-message";
import Divider from "components/divider/divider";
import { fullWidth } from "emotion/utils";

export default function MemberModal({
  setShowModal = () => {},
  showModal = true,
  activeMember,
  onSuccess = () => {},
  onClose = () => {},
}) {
  const {
    showModal: showConfirmationModal,
    handleExit,
    handleClose: handleConfirmationClose,
  } = useConfirmationModal(onClose);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalBodyHeight, setModalBodyHeight] = useState(0);
  const calculateModalHeight = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(".modal-content");
      const modalBody = document.querySelector(".modal-body");
      if (modalDialog) {
        setModalHeight(modalDialog.offsetHeight);
      }
      if (modalBody) {
        setModalBodyHeight(modalBody.offsetHeight);
      }
    }, 500); // delay
  };
  useEffect(() => {
    calculateModalHeight();
  }, []);
  useEffect(() => {
    if (showConfirmationModal) {
      calculateModalHeight();
    }
  }, [showConfirmationModal]);
  const formRef = useRef();

  const { data: clubs, isLoading: isClubsLoading } = useQuery(`/clubs`);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDefaultMessage, setIsDefaultMessage] = useState(true);
  const [customMessage, setCustomMessage] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [showHorsesOverlay, setShowHorsesOverlay] = useState(false);
  const [activeHorse, setActiveHorse] = useState(null);

  const { data: memberData } = useQuery(`/members/${activeMember?.id}`, {
    cacheTime: 0,
    enabled: !!activeMember?.id,
  });

  const member = memberData?.member;
  useEffect(() => {
    if (member && member?.user?.clubs) {
      member.clubId = member?.user.clubs[0].club.id;
    }
  }, [member]);

  const conditionalClose = () => {
    if (isFormDirty) {
      setCustomMessage();
      setIsDefaultMessage(false);
      handleExit();
    } else {
      setCustomMessage(null);
      setIsDefaultMessage(true);
      onClose();
      setShowModal(false);
    }
  };

  const handleResetConfirmation = async () => {
    try {
      const postData = {
        email: member?.user.email,
      };
      await Client.post("/auth/forgot-password", postData);
      alert("Email Sent!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };
  const handleConfirmation = () => {
    if (currentAction === "resetPassword") {
      handleResetConfirmation();
    } else if (currentAction === "remove") {
      //handleRemoveConfirmation(); // Use if remove is needed
    }
    setShowModal(false);
  };

  const clubOptions = clubs
    ? clubs.data.map((club) => ({
        value: club.id,
        label: club.name,
      }))
    : [clubs];
  const statusOptions = [
    { value: false, label: "Active" },
    { value: true, label: "Inactive" },
  ];

  function getCustodiansFromMember(member) {
    const custodians = (member?.member_custodians || []).map((mc) => {
      return {
        email: mc.custodian.user.email,
        relation: mc.relation,
        name: `${capitalizeFirstLetter(
          mc.custodian.user.first_name,
        )} ${capitalizeFirstLetter(mc.custodian.user.last_name)}`,
      };
    });

    return custodians;
  }

  const memberToFormik = () => {
    return {
      firstName: member?.user?.first_name,
      lastName: member?.user?.last_name,
      userName: member?.user?.username,
      email: member?.user?.email,
      birthAt:
        member?.user.birth_at && member?.user.birth_at.indexOf("T") > 0
          ? moment(member?.user.birth_at.split("T")[0]).format("MM/DD/yyyy")
          : "",
      startAt: member?.start_at || new Date(),
      clubId:
        (member?.user?.clubs || []).length > 0
          ? {
              value: member?.user.clubs[0].club.id,
              label: member?.user.clubs[0].club.name,
            }
          : "",
      banned: (member?.user?.banned ? true : false) || false,
      address: member?.user?.address,
      address2: member?.user?.address2,
      address_city: member?.user?.address_city,
      address_state: member?.user?.address_state,
      address_zip: member?.user?.address_zip,
    };
  };

  if (isClubsLoading) {
    return <div>Loading...</div>;
  }

  const getMaxBirth = () => {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 6);
    return d;
  };

  const getMinBirth = () => {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 20);
    return d;
  };

  const initialValues = memberToFormik();

  const getForm = (formik) => {
    return (
      // <FormBlock>
      <>
        <FormElement
          element="InputText"
          id="firstName"
          name="firstName"
          label="First name"
          placeholder="Member first name"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values["firstName"]}
          size="48"
          required
        />
        <FormErrorMessage name="firstName" formik={formik} />

        <FormElement
          element="InputText"
          id="lastName"
          name="lastName"
          label="Last name"
          placeholder="Member last name"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values["lastName"]}
          size="48"
          required
        />
        <FormErrorMessage name="lastName" formik={formik} />

        <FormElement
          element="InputText"
          id="userName"
          name="userName"
          label="Username"
          placeholder="Enter member username"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values["userName"]}
          size="48"
          required
        />
        <FormErrorMessage name="userName" formik={formik} />

        <FormElement
          element="InputText"
          id="email"
          name="email"
          label="Email"
          placeholder="Enter member email"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values["email"]}
          size="48"
          required
        />
        <FormErrorMessage name="email" formik={formik} />

        <FormElement
          element="Dropdown"
          id="clubId"
          name="clubId"
          label="Club"
          placeholder="Select club"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.clubId}
          options={clubOptions}
          size="48"
          required
        />

        <FormDateInput
          name="birthAt"
          label="Birthday"
          formik={formik}
          min={getMinBirth()}
          max={getMaxBirth()}
          size="48"
          required
        />
        {formik.touched.birthAt &&
          formik.errors.birthAt &&
          (calculateModalHeight(),
          (<span className="form-error-msg">{formik.errors.birthAt}</span>))}

        <FormElement
          element="Dropdown"
          id="banned"
          name="banned"
          label="Status"
          formik={formik}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.banned}
          options={statusOptions}
          size="48"
          required
        />

        {/* <FormTextInput
            name="customClub"
            hidden={formik.values["clubId"] !== "-1"}
            label="Club Name*"
            formik={formik}
          /> */}
        {/* {formik.touched.clubId &&
            formik.errors.clubId &&
            (calculateModalHeight(),
            (<span className="form-error-msg">{formik.errors.clubId}</span>))} */}

        {/* {formik.touched.customClub &&
            formik.errors.customClub &&
            (calculateModalHeight(),
            (<span className="form-error-msg">{formik.errors.customClub}</span>))} */}

        <Divider />

        <FormAddressBlock name="address" label="Address" formik={formik} />

        {/* <FormSwitch name="banned" label="Banned?" formik={formik} /> */}
      </>
      // </FormBlock>
    );
  };

  // const headerTitle = `${
  //   activeMember
  //     ? `Edit ${capitalize(activeMember?.user?.first_name.trim())} ${capitalize(
  //         activeMember?.user?.last_name.trim(),
  //       )}`
  //     : `Add member`
  // }`;
  const headerTitle = `${
    activeMember
      ? `Edit ${capitalize(activeMember?.user?.first_name.trim())} ${capitalize(
          activeMember?.user?.last_name.trim(),
        )}`
      : `Add member`
  }`;

  const btnBack = (
    <Button
      label="Cancel"
      onClick={() => setShowModal(false)}
      color="secondary"
      variant="outlined"
    />
  );
  const btnRemoveHorse = (
    <CustomButton
      key="remove"
      label="Remove Horse"
      type="outlined"
      color="tertiary"
      onClick={() => console.log("remove clicked")}
    />
  );
  const btnCreate = (formik) => (
    <Button
      label={member ? "Save" : "Save changes"}
      disabled={
        !Object.values(formik.touched).length ||
        Object.values(formik.errors).length
      }
      onClick={(e) => {
        formik.handleSubmit(e);
        calculateModalHeight();
      }}
    />
  );

  const btns = (formik) =>
    showHorsesOverlay
      ? [btnBack, btnRemoveHorse]
      : [btnBack, btnCreate(formik)];

  const footerButtons = (formik) =>
    [btns(formik)].map((btn, index) => (
      <React.Fragment key={index}>{btn}</React.Fragment>
    ));

  return (
    <>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        enableReinitialize
        onSubmit={async (data) => {
          let serverReturn = null;
          if (member?.id) {
            serverReturn = await Client.put(
              `/members/${member?.id}/member`,
              data,
            );
          } else {
            serverReturn = await Client.post("/members", data);
          }
          if (serverReturn?.success === false) {
            //Add an empty custodian row back in
            const field = serverReturn?.field;
            formRef?.current?.setErrors({ [field]: serverReturn?.reason });
          } else {
            setShowModal(false, serverReturn);
            onSuccess(serverReturn);
          }
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().label("First Name").required(),
          lastName: Yup.string().label("Last Name").required(),
          userName: Yup.string().label("Username").required(),
          email: Yup.string()
            .label("Email")
            .required()
            .test("email", "Email is invalid", (data) => {
              return !data || !emailValid(data);
            }),
          birthAt: Yup.string().label("Birthday").required(),
          clubId: Yup.mixed()
            .label("Club")
            .required()
            .test("club_test", "Club is required", (data) => {
              return data.length > 0;
            }),
          address1: Yup.string().label("Address"),
          city: Yup.string().label("City"),
          state: Yup.string().label("State"),
          zip: Yup.number().label("Zip Code"),
        })}
      >
        {(formik) => (
          <Form
            onChange={(evt) => {
              console.log("Change");
              const clone = structuredClone(formik.values);
              clone[evt.target.name] = evt.target.value;
              setIsFormDirty(
                JSON.stringify(clone) !== JSON.stringify(memberToFormik()),
              );
            }}
          >
            <CustomModal
              headerTitle={headerTitle}
              footerButtons={footerButtons(formik)}
              onHide={conditionalClose}
              size="md"
            >
              {getForm(formik)}
            </CustomModal>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleExit={handleExit}
        handleClose={handleConfirmationClose}
        handleGoBack={handleConfirmationClose}
        setShowModal={setShowModal}
        modalHeight={modalHeight}
        customMessage={customMessage}
        isDefaultMessage={isDefaultMessage}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
}
