import React from "react";
import { useMutation } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";

import Client from "client";

import CustomButton from "components/custom-button/custom-button";
import CustomModal from "components/custom-modal/custom-modal";
import { fullWidth } from "emotion/utils";

export default function ShowClassRemoveModal({
  setShowModal = () => {},
  showClasses,
  onAccept = () => {},
}) {
  const {
    mutateAsync: submitRequest,
    isLoading,
    // isError,
  } = useMutation({
    mutationKey: "remove-show-class",
    networkMode: "always",
    mutationFn: async (showClass) =>
      Client.delete(`/show-classes/${showClass.id}`),
    onSuccess: (values) => {
      setShowModal(false);
      onAccept();
    },
  });

  console.log(showClasses);
  const btnCancel = (
    <CustomButton
      key="cancel"
      label="Cancel"
      color="tertiary"
      type="outlined"
      onClick={() => setShowModal(false)}
    />
  );

  const btnRemove = (
    <CustomButton
      key="remove"
      label="Remove"
      color="tertiary"
      onClick={async () => {
        for (let x = 0; x < showClasses.length; x++) {
          await submitRequest(showClasses[x]);
        }
      }}
      loading={isLoading}
      disabled={isLoading}
    />
  );

  const footerButtons = [btnCancel, btnRemove];

  return (
    <CustomModal
      headerTitle="Remove Show Class?"
      footerButtons={footerButtons}
      onHide={() => setShowModal(false)}
    >
      <div style={{ alignItems: "center" }}>
        <p
          style={{
            ...fullWidth(),
            justifyContent: "center",
          }}
        >
          Are you sure you want to remove{" "}
          {showClasses?.length > 1
            ? `these ${showClasses.length} classes?`
            : "show class "}
        </p>
        <p
          style={{
            ...fullWidth(),
            fontWeight: 700,
            justifyContent: "center",
          }}
        >
          {showClasses.length === 1 ? showClasses[0].name + "?" : ""}
        </p>
      </div>
    </CustomModal>
  );
}
